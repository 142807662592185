import React, { useState, Fragment } from "react";
import { useLocation } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import * as propTypes from "prop-types";
import Sidebar from "../UI/Sidebar";
import TextField from "@material-ui/core/TextField";
import SearchIcon from "@material-ui/icons/Search";
import Dialog from "@material-ui/core/Dialog";
import Auth from "../auth/Auth";
import api from "../../config/api";
import { useWindowSize } from "../../helpers/resize";
import { closeAuth, logOut, setAuthPage } from "../../actions/authActions";
import { ReactComponent as Statistic } from "../../assets/images/icons/statistic.svg";
import { ReactComponent as Register } from "../../assets/images/icons/register_icon.svg";
import { ReactComponent as LogIn } from "../../assets/images/icons/log_in_icon.svg";
import { ReactComponent as User } from "../../assets/images/icons/user.svg";
import { ReactComponent as Exit } from "../../assets/images/icons/exit.svg";

function Navigation(props) {
  const [searchValue, setSearchValue] = useState("");
  const [isSidebar, setIsSidebar] = useState(false);
  const [width] = useWindowSize();
  const location = useLocation();

  const handleChange = (e) => setSearchValue(e.target.value);

  const handleBurgerClick = () => setIsSidebar(!isSidebar);

  const handleIconClick = () => {
    document.querySelector("input").focus();
  };

  const onSearch = (e) => {
    if (e.key === "Enter") {
      location.pathname === "/"
        ? api.QUESTION_SEARCH(searchValue)
        : api.ORGANISATION_SEARCH(searchValue);
    }
  };

  const { authOpen, dispatch, user, isAuthorized } = props;

  return (
    <>
      <p className="test-mode">платформа працює в тестовому режимі !!!</p>
      <div className={"navigation"}>
        <div className="navigation-burger" onClick={handleBurgerClick}>
          <div className="navigation-burgerMenuIcon"></div>
        </div>
        {isSidebar && (
          <div
            className="navigation-burgerMenuOverlay"
            onClick={handleBurgerClick}
          >
            <Sidebar closeBtn={handleBurgerClick} />
          </div>
        )}
        <div className={"search"} onClick={handleIconClick}>
          <SearchIcon style={{ cursor: "pointer" }} />
          <TextField
            onChange={handleChange}
            onKeyDown={onSearch}
            id="input-with-icon-grid"
            className={!searchValue ? "search-input" : "search-input underline"}
            label={
              width > 1000
                ? location.pathname === "/"
                  ? "Шукати питання..."
                  : "Шукати організацію..."
                : ""
            }
          />
        </div>
        <div className={"navigation-menu"}>
          <NavLink to={"/statistics"}>
            {width > 1000 && <p>Статистика</p>}
            <Statistic />
          </NavLink>
          {!user || !isAuthorized ? (
            <Fragment>
              <div
                className={"menu-item"}
                id={"register"}
                onClick={() => dispatch(setAuthPage("register"))}
              >
                {width > 1000 && <p>Зареєструватись</p>}
                <Register />
              </div>
              <div
                className={"menu-item"}
                id={"login"}
                onClick={() => dispatch(setAuthPage("login"))}
              >
                {width > 1000 && <p>Увійти</p>}
                <LogIn />
              </div>
            </Fragment>
          ) : (
            <Fragment>
              <div className={"menu-item"}>
                <NavLink to={"/profile"}>
                  {width > 1000 ? (
                    <p>{`${user.name} ${user.surname}`}</p>
                  ) : (
                    <User />
                  )}
                </NavLink>
              </div>
              <div className={"menu-item"} onClick={() => dispatch(logOut())}>
                {width > 1000 && <p>Вийти</p>}
                <Exit />
              </div>
            </Fragment>
          )}
        </div>
        <Dialog
          fullScreen
          open={authOpen}
          onClose={() => dispatch(closeAuth())}
        >
          <Auth />
        </Dialog>
      </div>
    </>
  );
}

Navigation.propTypes = {
  dispatch: propTypes.func.isRequired,
  authOpen: propTypes.bool,
  user: propTypes.object,
  isAuthorized: propTypes.bool,
};

function mapStateToProps({ auth }) {
  return {
    authOpen: auth.isOpen,
    user: auth.user,
    isAuthorized: auth.isAuthorized,
  };
}

export default connect(mapStateToProps)(Navigation);
