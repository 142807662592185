export const SET_AUTH_PAGE = 'SET_AUTH_PAGE'
export const CLOSE_AUTH_PAGE = 'CLOSE_AUTH_PAGE'

export const LOGIN_REQUEST_STARTED = 'LOGIN_REQUEST_STARTED'
export const LOGIN_REQUEST_SUCCESS = 'LOGIN_REQUEST_SUCCESS'
export const REQUEST_FAILURE = 'LOGIN_REQUEST_FAILURE'

export const LOGOUT = 'LOGOUT'

export const CREATE_ACC_STARTED = 'CREATE_ACC_STARTED'
export const CREATE_ACC_SUCCESS = 'CREATE_ACC_SUCCESS'
export const CREATE_ACC_FAILURE = 'CREATE_ACC_FAILURE'
