import React from 'react'
import * as propTypes from 'prop-types'

function InputSecret (props) {
  const { placeholder, name, register, handleChange, errors, isSubmitted } = props

  return (
    <div className="custom-input">
      <input
        ref={register}
        type="password"
        placeholder={placeholder}
        name={name}
        className={
          `custom-input__field ${errors[name] ? 'input-error' : ''} ${isSubmitted && !errors[name] ? 'input-valid' : ''}`
        }
        onChange={handleChange}
      />

      {errors[name] && errors[name].type === 'required' &&
        <span className="input-error-text">Будь ласка, заповніть поле.</span>
      }

      {errors[name] && errors[name].type === 'validate' &&
        <span className="input-error-text">Паролі мають співпадати</span>
      }

      {errors[name] && errors[name].type === 'minLength' &&
        <span className="input-error-text">Мінімальна довжина пароля 6 символів</span>
      }
    </div>
  )
}

InputSecret.propTypes = {
  placeholder: propTypes.string,
  name: propTypes.string.isRequired,
  register: propTypes.func.isRequired,
  handleChange: propTypes.func.isRequired,
  errors: propTypes.object.isRequired,
  isSubmitted: propTypes.bool.isRequired
}

export default InputSecret