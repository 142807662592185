import React, { Fragment, useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useWindowSize } from "../../../helpers/resize";
import propTypes from "prop-types";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { ReactComponent as Edit } from "../../../assets/images/icons/edit.svg";

const useStyles = makeStyles({
  table: {
    backgroundColor: "transparent",
  },
  head: {
    color: "#3FB2C6",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "16px",
  },
  row: {
    fontSize: "14px",
    lineHeight: "20px",
    color: "#000000",
  },
  collapse: {
    padding: 0,
  },
});

function DataTable(props) {
  const [data, setData] = useState([...(props.data ? props.data : [])]);
  const [width] = useWindowSize();

  const classes = useStyles();

  const openDescr = (index) => {
    data[index].isOpen = !data[index].isOpen;
    setData([...data]);
  };

  if (props && props.data && props.data.length) {
    const mobData = data.map((el) => ({ ...el, ...props.headers }));

    return (
      <TableContainer>
        <Table className={classes.table}>
          {width > 1200 ? (
            <>
              <TableHead>
                <TableRow>
                  {props.headers.map((head, index) => {
                    return (
                      <TableCell
                        className={classes.head}
                        key={index}
                        align="left"
                      >
                        {head}
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((item, index) => {
                  return (
                    <Fragment key={item.id}>
                      <TableRow>
                        <TableCell className={classes.row}>{item.id}</TableCell>
                        <TableCell className={`${classes.row} table__title`}>
                          {item.title}
                        </TableCell>
                        <TableCell className={classes.row}>
                          {item.event}
                        </TableCell>
                        <TableCell className={classes.row}>
                          {item.responsibleOrganisation}
                        </TableCell>
                        <TableCell className={classes.row}>
                          {item.subject}
                        </TableCell>
                        <TableCell className={classes.row}>
                          {!item.discussion_end_date &&
                          !item.discussion_start_date
                            ? "Розглядається"
                            : `з ${item.discussion_start_date} по ${item.discussion_end_date}`}
                        </TableCell>
                        <TableCell className={classes.row}>
                          {!item.responsibleUser ? "Розглядається" : "Прийнято"}
                        </TableCell>
                        {props.isEdit && (
                          <TableCell>
                            {item.responsibleUser ? (
                              item.responsibleUser
                            ) : (
                              <Fragment></Fragment>
                            )}
                          </TableCell>
                        )}
                        <TableCell className={classes.row}>
                          {props.isEdit && !item.responsibleUser ? (
                            <Edit
                              onClick={() => props.action(item.id)}
                              style={{ cursor: "pointer" }}
                            />
                          ) : (
                            <Fragment></Fragment>
                          )}
                        </TableCell>
                        <TableCell className={classes.row}>
                          <IconButton
                            style={{ color: "#992F87" }}
                            size="small"
                            onClick={() => openDescr(index)}
                          >
                            {item.isOpen ? (
                              <KeyboardArrowUpIcon />
                            ) : (
                              <KeyboardArrowDownIcon />
                            )}
                          </IconButton>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          className={[classes.row, classes.collapse].join(" ")}
                          colSpan={9}
                        >
                          <Collapse
                            in={item.isOpen}
                            timeout="auto"
                            unmountOnExit
                          >
                            <Box
                              margin={1}
                              style={{
                                paddingTop: "16px",
                                paddingBottom: "16px",
                              }}
                            >
                              {item.description}
                            </Box>
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    </Fragment>
                  );
                })}
              </TableBody>
            </>
          ) : (
            <TableBody>
              {mobData.map((el, i) => (
                <Fragment key={el.id}>
                  <TableRow style={{ background: "#f1f1f1" }}>
                    <TableCell className={`${classes.row} table__title`}>
                      {el[0]}
                    </TableCell>
                    <TableCell className={`${classes.row} table__title`}>
                      {el.id}
                    </TableCell>
                    {props.isEdit && (
                      <TableCell>
                        {el.responsibleUser ? (
                          el.responsibleUser
                        ) : (
                          <Fragment></Fragment>
                        )}
                      </TableCell>
                    )}
                    <TableCell className={classes.row}>
                      {props.isEdit && !el.responsibleUser ? (
                        <Edit
                          onClick={() => props.action(el.id)}
                          style={{ cursor: "pointer" }}
                        />
                      ) : (
                        <Fragment></Fragment>
                      )}
                    </TableCell>
                    <TableCell className={classes.row}>
                      <IconButton
                        style={{ color: "#992F87" }}
                        size="small"
                        onClick={() => openDescr(i)}
                      >
                        {el.isOpen ? (
                          <KeyboardArrowUpIcon />
                        ) : (
                          <KeyboardArrowDownIcon />
                        )}
                      </IconButton>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      className={[classes.row, classes.collapse].join(" ")}
                      colSpan={9}
                    >
                      <Collapse in={el.isOpen} timeout="auto" unmountOnExit>
                        <Box
                          margin={1}
                          style={{
                            paddingTop: "16px",
                            paddingBottom: "16px",
                          }}
                        >
                          {el.description}
                        </Box>
                      </Collapse>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.head}>{el[1]}</TableCell>
                    <TableCell className={`${classes.row} table__title`}>
                      {el.title}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.head}>{el[2]}</TableCell>
                    <TableCell className={classes.row}>{el.event}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.head}>{el[3]}</TableCell>
                    <TableCell className={classes.row}>
                      {el.responsibleOrganisation}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.head}>{el[4]}</TableCell>
                    <TableCell className={classes.row}>{el.subject}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.head}>{el[5]}</TableCell>
                    <TableCell className={classes.row}>
                      {!el.discussion_end_date && !el.discussion_start_date
                        ? "Розглядається"
                        : `з ${el.discussion_start_date} по ${el.discussion_end_date}`}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.head}>{el[6]}</TableCell>
                    <TableCell className={classes.row}>
                      {!el.responsibleUser ? "Розглядається" : "Прийнято"}
                    </TableCell>
                  </TableRow>
                </Fragment>
              ))}
            </TableBody>
          )}
        </Table>
      </TableContainer>
    );
  } else {
    return (
      <div className={"table"}>
        <p>Дані відсутні...</p>
      </div>
    );
  }
}

DataTable.propTypes = {
  data: propTypes.arrayOf(propTypes.object).isRequired,
  isEdit: propTypes.bool,
  action: propTypes.func,
  headers: propTypes.arrayOf(propTypes.string).isRequired,
};

export default DataTable;
