import React, { useState, Fragment } from "react";
import * as propTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import Autocomplete from "./inputs/Autocomplete";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: "14px",
    lineHeight: "20px",
    color: "#000000",
  },
  top: {
    background: "#F9F9F9",
    borderTop: "1px solid #E0E0E0",
  },
  topMiddle: {
    background: "#F9F9F9",
  },
  topOpen: {
    borderBottom: "1px solid #E0E0E0",
  },
  icon: {
    color: "#CC9900",
  },
}));

const CustomRadio = withStyles({
  root: {
    color: "#FEDD00",
    "&$checked": {
      color: "#FEDD00",
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

function CustomExpansionPanel(props) {
  const classes = useStyles();

  const [expanded, setExpanded] = useState(false);

  const handlePanel = (panel) => (evt, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const { data, filters, handleChange } = props;

  return (
    <div className={classes.root}>
      {props.type ? (
        <Fragment>
          <ExpansionPanel
            expanded={expanded === "panel1"}
            onChange={handlePanel("panel1")}
          >
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon className={classes.icon} />}
              className={`${classes.top} ${
                expanded === "panel1" ? "" : classes.topOpen
              }`}
            >
              <Typography className={classes.heading}>
                Тип організації
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <RadioGroup
                name="orgTypes"
                value={filters.orgTypes}
                onChange={props.handleChange}
              >
                {data.orgTypes &&
                  data.orgTypes.map((item) => {
                    return (
                      <FormControlLabel
                        key={item.id}
                        value={item.id.toString()}
                        control={<CustomRadio />}
                        label={
                          <label className="filters-popup__label">
                            {item.abbr} -{" "}
                            <span className="filters-popup__label--normal">
                              {item.name}
                            </span>
                          </label>
                        }
                      />
                    );
                  })}
              </RadioGroup>
            </ExpansionPanelDetails>
          </ExpansionPanel>

          <ExpansionPanel
            expanded={expanded === "panel2"}
            onChange={handlePanel("panel2")}
          >
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon className={classes.icon} />}
              className={`${classes.topMiddle} ${
                expanded === "panel1" ? classes.top : ""
              }`}
            >
              <Typography className={classes.heading}>
                Сфера діяльності
              </Typography>
            </ExpansionPanelSummary>
            {props.data.activity && (
              <ExpansionPanelDetails className={classes.content}>
                <div className="autocomplete-filter">
                  <Autocomplete
                    passData={(data) => props.passData(data)}
                    placeholder="Почніть вводити напрямок діяльності*"
                    data={props.data.activity}
                    queryParams={props.queryParams && props.queryParams}
                  />
                </div>
              </ExpansionPanelDetails>
            )}
          </ExpansionPanel>

          <ExpansionPanel
            expanded={expanded === "panel3"}
            onChange={handlePanel("panel3")}
          >
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon className={classes.icon} />}
              className={`${classes.top} ${
                expanded === "panel3" ? "" : classes.topOpen
              }`}
            >
              <Typography className={classes.heading}>
                Район діяльності
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className={classes.content}>
              <RadioGroup
                name="cityAreas"
                value={filters.cityAreas}
                onChange={handleChange}
              >
                {data.cityAreas &&
                  data.cityAreas.map((item) => {
                    return (
                      <FormControlLabel
                        key={item.id}
                        value={item.id.toString()}
                        control={<CustomRadio />}
                        label={item.name}
                      />
                    );
                  })}
              </RadioGroup>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </Fragment>
      ) : (
        <Fragment>
          <ExpansionPanel
            expanded={expanded === "panel1"}
            onChange={handlePanel("panel1")}
          >
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon className={classes.icon} />}
              className={`${classes.top} ${
                expanded === "panel1" ? "" : classes.topOpen
              }`}
            >
              <Typography className={classes.heading}>
                Форма консультації
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <RadioGroup
                name="consultation_form_id"
                value={filters.consultation_form_id}
                onChange={props.handleChange}
              >
                {data.consultsForm &&
                  data.consultsForm.map((item) => {
                    return (
                      <FormControlLabel
                        key={item.id}
                        value={item.id.toString()}
                        control={<CustomRadio />}
                        label={
                          <label className="filters-popup__label">
                            {item.abbr} -{" "}
                            <span className="filters-popup__label--normal">
                              {item.name}
                            </span>
                          </label>
                        }
                      />
                    );
                  })}
              </RadioGroup>
            </ExpansionPanelDetails>
          </ExpansionPanel>

          <ExpansionPanel
            expanded={expanded === "panel2"}
            onChange={handlePanel("panel2")}
          >
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon className={classes.icon} />}
              className={`${classes.top} ${
                expanded === "panel1" ? "" : classes.topOpen
              }`}
            >
              <Typography className={classes.heading}>Захід</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <RadioGroup
                name="event_id"
                value={filters.event_id}
                onChange={props.handleChange}
              >
                {data.events &&
                  data.events.map((item) => {
                    return (
                      <FormControlLabel
                        key={item.id}
                        value={item.id.toString()}
                        control={<CustomRadio />}
                        label={
                          <label className="filters-popup__label">
                            {item.abbr} -{" "}
                            <span className="filters-popup__label--normal">
                              {item.name}
                            </span>
                          </label>
                        }
                      />
                    );
                  })}
              </RadioGroup>
            </ExpansionPanelDetails>
          </ExpansionPanel>

          <ExpansionPanel
            expanded={expanded === "panel3"}
            onChange={handlePanel("panel3")}
          >
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon className={classes.icon} />}
              className={`${classes.top} ${
                expanded === "panel3" ? "" : classes.topOpen
              }`}
            >
              <Typography className={classes.heading}>ГО</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <RadioGroup
                name="subject_organisation_id"
                value={filters.subject_organisation_id}
                onChange={props.handleChange}
              >
                {data.orgGO &&
                  data.orgGO.map((item) => {
                    return (
                      <FormControlLabel
                        key={item.id}
                        value={item.id.toString()}
                        control={<CustomRadio />}
                        label={
                          <label className="filters-popup__label">
                            {item.abbr} -{" "}
                            <span className="filters-popup__label--normal">
                              {item.name}
                            </span>
                          </label>
                        }
                      />
                    );
                  })}
              </RadioGroup>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </Fragment>
      )}
    </div>
  );
}

CustomExpansionPanel.propTypes = {
  data: propTypes.object.isRequired,
  filters: propTypes.object.isRequired,
  handleChange: propTypes.func.isRequired,
  passData: propTypes.func,
  type: propTypes.string,
  queryParams: propTypes.string,
};

export default CustomExpansionPanel;
