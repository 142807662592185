import {
  GET_CONSULT,
  GET_CONSULT_DETAILS,
  REFETCH_CONSULT,
  REFETCH_CONSULT_DETAILS,
  ERROR_CONSULT
} from '../actions/types/consulations'

const initialState = {
  consulations: {},
  loading: false,
  error: false,
  consulation: null
}

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_CONSULT:
      return {
        ...state,
        consulations: action.payload,
        loading: true,
        error: false
      }
    case GET_CONSULT_DETAILS:
      return {
        ...state,
        consulation: action.payload
      }
    case REFETCH_CONSULT_DETAILS:
      return {
        ...state,
        consulation: null
      }
    case REFETCH_CONSULT:
      return {
        ...state,
        loading: false,
        error: false
      }
    case ERROR_CONSULT:
      return {
        ...state,
        consulations: {},
        loading: true,
        error: true
      }
    default:
      return state
  }
}
