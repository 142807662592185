import axios from "axios";
import { config } from "../env/enviroment";

const instance = axios.create({
  baseURL: config.baseURL,
  timeout: 10000000,
  headers: {
    "Content-Type": "application/json",
  },
});

export default instance;
