import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import * as propTypes from "prop-types";
import { setAuthPage } from "../../actions/authActions";
import "./NoAuthModal.css";

const NoAuthModal = ({ onClose, dispatch }) => (
  <div className="noAuthOverlay">
    <div className="noAuthWrapper">
      <h2 className="noAuthText">
        Для роботи з системою Вам необхідно авторизуватися
      </h2>
      <Link className={"button-violet noAuthBtn"} to="#" onClick={onClose}>
        Скасувати
      </Link>
      <Link
        className={"button-blue noAuthBtn"}
        to="/"
        onClick={() => dispatch(setAuthPage("login"))}
      >
        Авторизація
      </Link>
      <div className="noAuthCloseBtnWrapper">
        <div className="noAuthCloseBtnArea" onClick={onClose}>
          <div className="noAuthCloseBtn"></div>
        </div>
      </div>
    </div>
  </div>
);

NoAuthModal.propTypes = {
  dispatch: propTypes.func.isRequired,
  // authOpen: propTypes.bool,
};

function mapStateToProps({ auth }) {
  return {
    authOpen: auth.isOpen,
    user: auth.user,
    isAuthorized: auth.isAuthorized,
  };
}

export default connect(mapStateToProps)(NoAuthModal);
