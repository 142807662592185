import React, {Component} from "react";
import IconButton from "@material-ui/core/IconButton";
import {ReactComponent as CloseIcon} from '../../assets/images/icons/close.svg';
import img from '../../assets/images/pages/main-img.png'
import LoginForm from "./forms/LoginForm";
import {ReactComponent as Google} from '../../assets/images/icons/google_red.svg';
import {ReactComponent as Fb} from '../../assets/images/icons/fb_blue.svg';
import {ReactComponent as RegisterBg} from '../../assets/images/icons/login_button.svg';
import {ReactComponent as UserAdd} from "../../assets/images/icons/user_add.svg";
import Fab from "@material-ui/core/Fab";
import * as propTypes from "prop-types";
import {connect} from "react-redux";
import {closeAuth, logIn, setAuthPage} from "../../actions/authActions";

class Login extends Component{
	constructor(props) {
		super(props);
	}

	onLogin = (val) => {
		this.props.dispatch(logIn(val))
	};

	render() {
		const {dispatch} = this.props;
		return(
			<div className={'login'}>
				<div className={'login-close'}>
					<IconButton edge="start" color="inherit" onClick={() => dispatch(closeAuth())} aria-label="close">
						<CloseIcon style={{width: '26px', height: '26px'}}/>
					</IconButton>
				</div>
				<div className={'login-body'}>
					<img src={img} alt=""/>
					<h2>Увійти в аккаунт</h2>
					<LoginForm onSubmit={this.onLogin}/>
					<div className={'socials'}>
						<Google/>
						<Fb/>
					</div>
					<div className={'move-to'}>
						<h3>У вас ще не має аккаунту?</h3>
						<p>Натисніть кнопку нижче, щоб зареєструватись.</p>
						<div className={'move-to--btn'}>
							<RegisterBg/>
							<Fab onClick={() => dispatch(setAuthPage('register'))}>
								<UserAdd/>
							</Fab>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

Login.propTypes = {
	dispatch: propTypes.func.isRequired,
	token: propTypes.string,
	user: propTypes.object,
	authPage: propTypes.string,
	authOpen: propTypes.bool,
	error: propTypes.object
};

function mapStateToProps({auth}) {
	return {
		token: auth.token,
		user: auth.user,
		authPage: auth.authPage,
		authOpen: auth.isOpen,
		error: auth.error
	}
}

export default connect(mapStateToProps)(Login);
