import React, { useState, useEffect, Fragment } from 'react'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import CountUp from 'react-countup'
import CircularProgress from '@material-ui/core/CircularProgress'
import Dialog from '@material-ui/core/Dialog'
import ConsultFilters from '../../containers/ConsultFilters'
import Pagination from '../../UI/Pagination'
import TableConsult from '../../containers/tables/TableConsults'
import ConsultationRequest from '../../containers/modals/ConsultationRequest'
import { FETCH_USER_CONSULT, ACCEPT_CONSULT, FETCH_CONSULT_DETAILS } from '../../../actions/cunsulationActions'
import { FETCH_USER_ORG_MEMBER } from '../../../actions/organisationsActions'
import { REFETCH_CONSULT_DETAILS } from '../../../actions/types/consulations'
import { ReactComponent as Users } from '../../../assets/images/icons/users_grey.svg'

const headers = ['ID', 'Назва', 'Захід', 'Структурний підрозділ', 'Тема', 'Строки проведення', 'Статус', 'Відповідальний', '', '']

const useStyles = makeStyles({
  root: {
    color: '#992F87'
  }
})

function UserConsults (props) {
  const classes = useStyles()

  const [ filters, setFilters ] = useState({})

  const [ pagination, setPagination ] = useState({})

  const [ isOpen, setIsOpen ] = useState(false)

  const [ selectItem, setSelectItem ] = useState(null)

  useEffect(() => {
    if (!selectItem) {
      props.dispatch(FETCH_USER_ORG_MEMBER())
    } else {
      props.dispatch(FETCH_CONSULT_DETAILS(selectItem))
    }
  }, [selectItem, props.orgMember])

  const fetchData = (data) => {
    setFilters({
      ...filters,
      ...data
    })
    
    props.dispatch(FETCH_USER_CONSULT({...data, ...pagination, responsible_organisation_id: props.orgMember, responsible_user_id: `${props.responsibleUser}`}))
  }

  const fetchDataWithPagination = (data) => {
    setPagination({
      ...pagination,
      ...data
    })
    props.dispatch(FETCH_USER_CONSULT({...data, ...filters, responsible_organisation_id: props.orgMember, responsible_user_id: `${props.responsibleUser}`}))
  }

  const reFetchData = (isUpdate) => {

    
    if (isUpdate) {
      props.dispatch({
        type: REFETCH_CONSULT_DETAILS
      })
    }
    setIsOpen(!isOpen)
  }

  return (
    <div className="main">
      <div className="main__scrollable">
        <div className="main__count">
          <div>
            <h2 className="main__caption">Вхідні запитання</h2>
          </div>
          <div className="main__counter">
            <CountUp start={0} end={12355} delay={0.1} duration={1.9} />
            <Users />
          </div>
        </div>
        <ConsultFilters fetchData={fetchData} />
        {!props.loading ? (
          <div className="table-wrapper">
            <CircularProgress
              className={classes.root}
            ></CircularProgress>
          </div>
        ) : (
          <Fragment>
            <TableConsult
              data={props.consulations}
              headers={headers}
              isEdit={true}
              action={(id) => { setIsOpen(!isOpen); setSelectItem(id) }}
            />
            <Pagination variant="pagination" fetchData={fetchDataWithPagination} pagination={props.pagination} />
          </Fragment>
        )}
      </div>
      {props.consulation &&
        <Dialog fullScreen open={isOpen} onClose={() => setIsOpen(!isOpen)}>
          <ConsultationRequest orgMember={props.orgMember} type="accept" onClose={(isUpdate) => reFetchData(isUpdate)} action={(data) => ACCEPT_CONSULT(selectItem, data)} />
        </Dialog>
      }
    </div>
  )
}

function mapStateToProps({ auth, consulations, organisations }) {
  return {
    consulations: consulations.consulations.data,
    consulation: consulations.consulation,
    loading: consulations.loading,
    pagination: consulations.consulations.pagination,
    orgMember: organisations.orgMember,
    responsibleUser: auth.user.id
  }
}

export default connect(mapStateToProps)(UserConsults)
