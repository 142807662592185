import React from 'react'
import * as propTypes from 'prop-types'

function Input (props) {
  const { placeholder, name, register, handleChange, errors, isSubmitted, disabled } = props

  return (
    <div className="custom-input">
      <input
        disabled={disabled}
        ref={register}
        type="text"
        placeholder={placeholder}
        name={name}
        className={
          `custom-input__field ${errors[name] ? 'input-error' : ''} ${isSubmitted && !errors[name] ? 'input-valid' : ''}`
        }
        onChange={handleChange}
        
      />

      {errors[name] && errors[name].type === 'required' &&
        <span className="input-error-text">Будь ласка, заповніть поле.</span>
      }

      {errors[name] && errors[name].type === 'pattern' &&
        <span className="input-error-text">{errors[name].message}.</span>
      }
    </div>
  )
}

Input.propTypes = {
  placeholder: propTypes.string,
  name: propTypes.string.isRequired,
  register: propTypes.func.isRequired,
  handleChange: propTypes.func.isRequired,
  errors: propTypes.object.isRequired,
  isSubmitted: propTypes.bool.isRequired,
  disabled: propTypes.bool
}

export default Input