import React, { useState, Fragment } from 'react'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import CountUp from 'react-countup'
import TableFilters from '../../containers/TableFilters'
import Table from '../../containers/tables/TableUserOrgReq'
// import Pagination from '../../UI/Pagination'
import CircularProgress from '@material-ui/core/CircularProgress'
import { FETCH_USER_ORG_REQ } from '../../../actions/organisationsActions'
import { ReactComponent as Users } from '../../../assets/images/icons/users_grey.svg'

const useStyles = makeStyles({
  root: {
    color: '#992F87'
  }
})

function UserOrgReq (props) {
  const classes = useStyles()

  const [ filters, setFilters ] = useState({})

  const [ pagination, setPagination ] = useState({})

  const fetchData = (data) => {
    setFilters({
      ...filters,
      ...data
    })
    props.dispatch(FETCH_USER_ORG_REQ({...data, ...pagination}))
  }

  // const fetchDataWithPagination = (data) => {
  //   setPagination({
  //     ...pagination,
  //     ...data
  //   })
  //   props.dispatch(FETCH_USER_ORG_REQ({...data, ...filters}))
  // }

  return (
    <div className="main">
      <div className="main__scrollable">
        <div className="main__count">
          <div>
            <h2 className="main__caption">Інші організації</h2>
          </div>
          <div className="main__counter">
            <CountUp start={0} end={12355} delay={0.1} duration={1.9} />
            <Users />
          </div>
        </div>
        <TableFilters fetchData={fetchData} />
        {!props.loading ? (
          <div className="table-wrapper">
            <CircularProgress
              className={classes.root}
            ></CircularProgress>
          </div>
        ) : (
          <Fragment>
            <Table
              data={props.userOrganisationsReq}
            />
            {/* <Pagination variant="pagination" fetchData={fetchDataWithPagination} pagination={props.pagination} /> */}
          </Fragment>
        )}
      </div>
    </div>
  )
}

function mapStateToProps({ userOrganisationsReq }) {
  return {
    userOrganisationsReq: userOrganisationsReq.userOrganisationsReq.data,
    // pagination: userOrganisationsReq.userOrganisationsReq.pagination,
    loading: userOrganisationsReq.loading
  }
}

export default connect(mapStateToProps)(UserOrgReq)
