import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import * as propTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import ExpansionPanel from "../../UI/ExpansionPanel";
import { useWindowSize } from "../../../helpers/resize";
import { ReactComponent as Done } from "../../../assets/images/icons/done.svg";

function FiltersPopup(props) {
  const [filters, setFilters] = useState({ ...props.passFiltersToPopup });
  const [width] = useWindowSize();

  useEffect(() => {
    if (props.filters)
      setFilters({
        ...filters,
        ...props.filters,
      });
  }, [props.filters]);

  const handleChange = (evt) => {
    filters[evt.target.name] = evt.target.value;
    setFilters({ ...filters });
  };

  const passData = (data) => {
    filters.activity = data;
    setFilters({ ...filters });
  };

  const checkFilters = () => {
    if (props.type) {
      if (
        filters.activity === "" &&
        filters.orgTypes === "" &&
        filters.cityAreas === ""
      ) {
        [...document.querySelectorAll(".filters-popup__btn")].forEach((btn) => {
          btn.classList.add("button-disabled");
        });
        return true;
      } else {
        [...document.querySelectorAll(".filters-popup__btn")].forEach((btn) => {
          btn.classList.remove("button-disabled");
        });
      }
    } else {
      if (
        filters.consultation_form_id === "" &&
        filters.event_id === "" &&
        filters.subject_organisation_id === ""
      ) {
        [...document.querySelectorAll(".filters-popup__btn")].forEach((btn) => {
          btn.classList.add("button-disabled");
        });
        return true;
      } else {
        [...document.querySelectorAll(".filters-popup__btn")].forEach((btn) => {
          btn.classList.remove("button-disabled");
        });
      }
    }
  };

  const clearFilters = () => {
    props.clearFilters();
    setFilters(props.passFiltersToPopup);
  };

  return (
    <Grid item xs={11}>
      <div className="filters-popup">
        <div className="filters-popup__top">
          <p className="filters-popup__top--left">Оберіть фільтри зі списку</p>
          <div className="filters-popup__top--right">
            <button
              disabled={checkFilters()}
              onClick={clearFilters}
              className="filters-popup__btn filters-popup__btn--clear"
            >
              {width > 767 && <span>Очистити фільтри</span>}
              <span className="material-icons">clear</span>
            </button>
            <button
              disabled={checkFilters()}
              onClick={() => props.selectFilters(filters)}
              className="filters-popup__btn filters-popup__btn--select"
            >
              {width > 767 && <span>Застосувати Фільтр</span>}
              <Done />
            </button>
          </div>
        </div>
        <ExpansionPanel
          type={props.type && props.type}
          passData={(data) => passData(data)}
          data={props.type ? props.data : { ...props.lists, ...props.orgGO }}
          handleChange={handleChange}
          filters={filters}
          queryParams={props.queryParams && props.queryParams}
        />
      </div>
    </Grid>
  );
}

FiltersPopup.propTypes = {
  clearFilters: propTypes.func.isRequired,
  selectFilters: propTypes.func.isRequired,
  passFiltersToPopup: propTypes.object.isRequired,
  type: propTypes.string,
  filters: propTypes.object,
  queryParams: propTypes.string,
};

function mapStateToProps({ lists, organisations }) {
  return {
    data: lists.filters,
    lists: lists.consultFilters,
    orgGO: organisations.orgGO,
  };
}

export default connect(mapStateToProps)(FiltersPopup);
