import React from 'react'
import { Field, reduxForm } from 'redux-form'
import Input from "./fields/Input";
import CircularProgress from "@material-ui/core/CircularProgress";
import {ReactComponent as NextYellow} from '../../../assets/images/icons/btn_next_yellow.svg';
import {ReactComponent as NextWhite} from '../../../assets/images/icons/btn_next_white.svg';
import {connect} from "react-redux";
import * as propTypes from "prop-types";

const validate = values => {
	const errors = {};
	const requiredFields = [
		'email',
		'password'
	];
	requiredFields.forEach(field => {
		if (!values[field]) {
			errors[field] = 'Будь ласка, заповніть поле.'
		}
	});
	if (
		values.email &&
		!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
	) {
		errors.email = 'Невірно вказаний email'
	}
	return errors;
};

let LoginForm = props => {
	const { handleSubmit, invalid, loading } = props;
	return <form onSubmit={handleSubmit}>
		<Field name="email" component={Input} placeholder="Email*" width={340} type="text" variant={1}/>
		<Field name="password" component={Input} placeholder="Пароль*" width={340} type="password" variant={1}/>
		{
			loading ? <CircularProgress style={{color: '#FEDD00', height: '45px', width: '45px'}}/>
			:	<button disabled={invalid} type="submit">{invalid ? <NextYellow/> : <NextWhite/>}</button>
		}
	</form>
};

LoginForm = reduxForm({
	form: 'login', validate
})(LoginForm);

LoginForm.propTypes = {
	dispatch: propTypes.func.isRequired,
	loading: propTypes.bool
};

function mapStateToProps({auth}) {
	return {
		loading: auth.loading
	}
}

export default connect(mapStateToProps)(LoginForm);
