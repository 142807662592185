import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import propTypes from 'prop-types'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { ReactComponent as Fb } from '../../../assets/images/icons/fb.svg'
import { ReactComponent as Inst } from '../../../assets/images/icons/inst.svg'

const useStyles = makeStyles({
  table: {
    backgroundColor: 'transparent'
  },
  head: {
    color: '#3FB2C6',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '16px'
  },
  row: {
    fontSize: '14px',
    lineHeight: '20px',
    color: '#000000'
  }
})

const headers = ['ПІБ', 'Телефон', ' E-mail', 'Соціальні мережі']

function DataTable(props) {
  const classes = useStyles()

  if (props.data.length) {
    return (
      <TableContainer>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              {
                headers.map((head, index) => {
                  return <TableCell className={classes.head} key={index} align="left">{head}</TableCell>
                })
              }
            </TableRow>
          </TableHead>
          <TableBody>
            {
              props.data.map(item => {
                return (
                  <TableRow key={item.id}>
                    <TableCell className={`${classes.row} table__title`}>{`${item.surname} ${item.name} ${item.patronymic}`}</TableCell>
                    <TableCell className={classes.row}>{item.phone}</TableCell>
                    <TableCell className={classes.row}>{item.email}</TableCell>
                    <TableCell>
                      <div className="table__cell">
                        <a className="table__cell-link" href={item.social_links.fb}><Fb /></a>
                        <a className="table__cell-link" href={item.social_links.instagram}><Inst /></a>
                      </div>
                    </TableCell>
                  </TableRow>
                )
              })
            }
          </TableBody>
        </Table>
      </TableContainer>
    )
  } else {
    return (
      <div className={'table'}>
        <p>Дані відсутні...</p>
      </div>
    )
  }
}

DataTable.propTypes = {
  data: propTypes.arrayOf(propTypes.object).isRequired
}

export default DataTable