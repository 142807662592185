import React, { Component } from "react";
import { connect } from "react-redux";
import * as propTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/styles";
import qs from "query-string";
import Backdrop from "@material-ui/core/Backdrop";
import Select from "../UI/inputs/FilterSelect";
import FiltersPopup from "./modals/FiltersPopup";
import { FETCH_FILTERS } from "../../actions/listsAction";
import { ReactComponent as Search } from "../../assets/images/icons/search_yellow.svg";
import { ReactComponent as Setting } from "../../assets/images/icons/setting_icon.svg";

const useStyles = () => ({
  backdrop: {
    zIndex: 1000,
  },
});

let passFiltersToPopup = {
  activity: "",
  orgTypes: "",
  cityAreas: "",
};

class TableFilters extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filters: {
        type_id: null,
        city_id: null,
        area_id: null,
        activity_field_id: null,
        id: null,
        edrpou: null,
      },
      isOpen: false,
    };
  }

  componentDidMount() {
    this.props.dispatch(FETCH_FILTERS());
    if (Object.keys(qs.parse(this.props.location.search)).length) {
      this.setState({
        filters: {
          ...this.state.filters,
          ...qs.parse(this.props.location.search),
        },
      });
    } else {
      this.props.fetchData();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.filters !== prevState.filters) {
      this.props.history.push(
        `${this.props.match.path}?${qs.stringify(this.state.filters, {
          skipNull: true,
          skipEmptyString: true,
        })}`
      );
      this.props.fetchData(this.state.filters);
    }
  }

  handleChange = (evt) => {
    if (+this.state.filters[evt.target.id] !== +evt.target.dataset.value) {
      this.setState({
        filters: {
          ...this.state.filters,
          [evt.target.id]: +evt.target.dataset.value,
        },
      });
    } else {
      this.setState({
        filters: {
          ...this.state.filters,
          [evt.target.id]: null,
        },
      });
    }
  };

  handleInput = (evt) => {
    if (evt.target.value) {
      this.setState({
        filters: {
          ...this.state.filters,
          [evt.target.id]: evt.target.value,
        },
      });
    } else {
      this.setState({
        filters: {
          ...this.state.filters,
          [evt.target.id]: null,
        },
      });
    }
  };

  checkVal = (evt) => {
    if (evt.keyCode < 48 || evt.keyCode > 57) {
      if (evt.keyCode === 8) {
        return;
      }
      evt.preventDefault();
    }
  };

  toggleModalFilters = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };

  closeModalFilters = (evt) => {
    if (evt.target.id === "backdrop") {
      this.toggleModalFilters();
    }
  };

  handleFilters = (data) => {
    this.setState({
      filters: {
        ...this.state.filters,
        type_id: data.orgTypes,
        activity_field_id: data.activity,
        area_id: data.cityAreas,
      },
    });
    this.toggleModalFilters();
  };

  clearFilters = () => {
    this.setState({
      filters: {
        type_id: null,
        area_id: null,
        activity_field_id: null,
        id: null,
        edrpou: null,
      },
    });
    this.toggleModalFilters();
  };

  render() {
    const { activity, orgTypes, citys, cityAreas } = this.props.lists;

    return (
      <div>
        <Backdrop
          id="backdrop"
          className={this.props.classes.backdrop}
          open={this.state.isOpen}
          onClick={this.closeModalFilters}
        >
          <FiltersPopup
            queryParams={qs.parse(this.props.location.search).activity_field_id}
            type="organisation"
            filters={qs.parse(this.props.location.search)}
            passFiltersToPopup={passFiltersToPopup}
            selectFilters={this.handleFilters}
            clearFilters={this.clearFilters}
          />
        </Backdrop>
        <div className={"table-filters"}>
          <div className={"table-filters__inner"}>
            <input
              value={this.state.filters.id ? this.state.filters.id : ""}
              className={"table-filters__field"}
              id="id"
              onKeyDown={this.checkVal}
              onChange={this.handleInput}
              type="text"
              placeholder={"Введіть ID..."}
            />
            <Search />
          </div>
          <div className={"table-filters__inner"}>
            <input
              value={this.state.filters.edrpou ? this.state.filters.edrpou : ""}
              className={"table-filters__field"}
              id="edrpou"
              type="text"
              placeholder={"ЄДРПОУ..."}
              onKeyDown={this.checkVal}
              onChange={this.handleInput}
            />
            <Search />
          </div>
          <Select
            selectedId={qs.parse(this.props.location.search).activity_field_id}
            handleChange={this.handleChange}
            id="activity_field_id"
            data={activity}
            name="Сфера діяльності"
          />
          <Select
            selectedId={qs.parse(this.props.location.search).type_id}
            handleChange={this.handleChange}
            id="type_id"
            data={orgTypes}
            name="Тип"
          />
          <Select
            selectedId={qs.parse(this.props.location.search).area_id}
            handleChange={this.handleChange}
            id="area_id"
            data={cityAreas}
            name="Район"
          />
          <button
            className={"table-filters__btn"}
            onClick={this.toggleModalFilters}
          >
            Фільтри
            <Setting />
          </button>
        </div>
      </div>
    );
  }
}

function mapStateToProps({ lists }) {
  return {
    lists: lists.filters,
  };
}

TableFilters.propTypes = {
  fetchData: propTypes.func.isRequired,
};

export default withRouter(
  connect(mapStateToProps)(withStyles(useStyles)(TableFilters))
);
