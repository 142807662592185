import React from "react";
import * as propTypes from "prop-types";
import { connect } from "react-redux";
import IconButton from "@material-ui/core/IconButton";
import CreateOrgForm from "../forms/CreateOrgForm";
import {
  FETCH_ORG,
  FETCH_USER_ORG,
} from "../../../actions/organisationsActions";
import { ReactComponent as CloseIcon } from "../../../assets/images/icons/close.svg";

function CreateOrg(props) {
  const onSubmit = (data) => {
    props.dispatch(props.action(data)).then(() => {
      props.dispatch(FETCH_ORG());
      props.dispatch(FETCH_USER_ORG());
      props.onClose();
    });
  };

  return (
    <div className="organisation">
      <div className="organisation__close-icon">
        <IconButton
          edge="start"
          color="inherit"
          onClick={() => props.onClose(true)}
          aria-label="close"
        >
          <CloseIcon style={{ width: "26px", height: "26px" }} />
        </IconButton>
      </div>
      <h2 className="organisation__title">Реєстрація ГО</h2>
      <CreateOrgForm
        onSubmit={(data) => onSubmit(data)}
        type={props.type && props.type}
      />
    </div>
  );
}

CreateOrg.propTypes = {
  action: propTypes.func,
  onClose: propTypes.func,
  type: propTypes.string,
};

export default connect()(CreateOrg);
