import React, {Fragment} from "react";

const Input = (
	{
		placeholder,
		input,
		filled,
		width,
		variant,
		meta: {touched, invalid, error},
		...custom
	}) => {
	return(
		<Fragment>
			<div className={`input`} style={{width: width}}>
				<input {...custom} {...input} placeholder={placeholder} className={`${[...input.value].length > 0 && 'filled'} ${(touched && error) && 'error'}`}/>
				<p className={'error'}>{touched && error}</p>
			</div>
		</Fragment>
	);
};

export default Input;
