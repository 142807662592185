import { applyMiddleware, combineReducers, createStore, compose } from 'redux'
import thunk from 'redux-thunk'
import { reducer as formReducer } from 'redux-form'
import auth from '../reducers/AuthReducer'
import organisations from '../reducers/organisationsReducer'
import organisation from '../reducers/orgDetailsReducer'
import userOrganisations from '../reducers/userOrgReducer'
import userOrganisationsReq from '../reducers/userOrgReqReducer'
import lists from '../reducers/listsReducer'
import consulations from '../reducers/consultsReducer'
import snackBar from '../reducers/snackBarReducer'

const rootReducer = combineReducers({
  auth,
  form: formReducer,
  organisations,
  organisation,
  userOrganisations,
  userOrganisationsReq,
  lists,
  consulations,
  notifications: snackBar
})

export default function configureStore() {
  return createStore(
    rootReducer,
    compose(
      applyMiddleware(thunk),
      window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : f => f,
    )
  )
}
