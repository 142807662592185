import React, { useState, useEffect, Fragment } from 'react'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import CountUp from 'react-countup'
import TableFilters from '../../containers/TableFilters'
import Table from '../../containers/tables/TableUserOrg'
import Pagination from '../../UI/Pagination'
import CreateOrg from '../../containers/modals/CreateOrg'
import CircularProgress from '@material-ui/core/CircularProgress'
import Dialog from '@material-ui/core/Dialog'
import { FETCH_USER_ORG, FETCH_ORG_DETAILS, EDIT_ORG } from '../../../actions/organisationsActions'
import { REFETCH_ORG_DETAILS } from '../../../actions/types/organisations'
import { ReactComponent as Users } from '../../../assets/images/icons/users_grey.svg'

const useStyles = makeStyles({
  root: {
    color: '#992F87'
  }
})

function UserOrg(props) {
  const classes = useStyles()

  const [ filters, setFilters ] = useState({})

  const [ pagination, setPagination ] = useState({})

  const [ isOpen, setIsOpen ] = useState(false)

  const [ selectItem, setSelectItem ] = useState(null)

  useEffect(() => {
    if (selectItem) {
      props.dispatch(FETCH_ORG_DETAILS(selectItem))
    }
  }, [selectItem])

  const fetchData = (data) => {
    setFilters({
      ...filters,
      ...data
    })
    props.dispatch(FETCH_USER_ORG({...data, ...pagination}))
  }

  const fetchDataWithPagination = (data) => {
    setPagination({
      ...pagination,
      ...data
    })
    props.dispatch(FETCH_USER_ORG({...data, ...filters}))
  }

  const reFetchData = (isUpdate) => {
    if (isUpdate) {
      props.dispatch({
        type: REFETCH_ORG_DETAILS
      })
    }
    setIsOpen(!isOpen)
  }

  return (
    <div className="main">
      <div className="main__scrollable">
        <div className="main__count">
          <div>
            <h2 className="main__caption">Мої організації</h2>
          </div>
          <div className="main__counter">
            <CountUp start={0} end={12355} delay={0.1} duration={1.9} />
            <Users />
          </div>
        </div>
        <TableFilters fetchData={fetchData} />
        {!props.loading ? (
          <div className="table-wrapper">
            <CircularProgress
              className={classes.root}
            ></CircularProgress>
          </div>
        ) : (
          <Fragment>
            <Table
              data={props.userOrganisations}
              action={(id) => { setIsOpen(!isOpen); setSelectItem(id) }}
            />
            <Pagination variant="pagination" fetchData={fetchDataWithPagination} pagination={props.pagination} />
          </Fragment>
        )}
      </div>
      {props.organisation &&
        <Dialog fullScreen open={isOpen} onClose={() => setIsOpen(!isOpen)}>
          <CreateOrg type="edit" onClose={(isUpdate) => reFetchData(isUpdate)} action={(data) => EDIT_ORG(selectItem, data)} />
        </Dialog>
      }
    </div>
  )
}

function mapStateToProps({ organisation, userOrganisations }) {
  return {
    userOrganisations: userOrganisations.userOrganisations.data,
    pagination: userOrganisations.userOrganisations.pagination,
    loading: userOrganisations.loading,
    organisation: organisation.organisation
  }
}

export default connect(mapStateToProps)(UserOrg)
