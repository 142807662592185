import React from 'react'
import ProfileForm from '../containers/forms/ProfileForm'

function UserProfile () {
  return (
    <div className="profile">
      <ProfileForm />
    </div>
  )
}

export default UserProfile
