import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import Dialog from "@material-ui/core/Dialog";
import ConsultationRequest from "../containers/modals/ConsultationRequest";
import CreateOrg from "../containers/modals/CreateOrg";
import { CREATE_CONSULT } from "../../actions/cunsulationActions";
import { CREATE_ORG } from "../../actions/organisationsActions";
import { ReactComponent as Question } from "../../assets/images/icons/question_violet_icon.svg";
import { ReactComponent as Users } from "../../assets/images/icons/users_violet_icon.svg";
import { ReactComponent as Speach } from "../../assets/images/icons/speach_violet_icon.svg";

class PlusMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      consultationOpen: false,
      createOrgOpen: false,
      dialogOpen: false,
    };
  }

  toggleOpen = () => {
    this.setState({
      open: !this.state.open,
    });
  };

  toggleDialog = () => {
    this.setState({
      open: false,
      dialogOpen: !this.state.dialogOpen,
    });
  };

  toggleConsultation = (bool) => {
    this.setState({
      dialogOpen: !this.state.dialogOpen,
      consultationOpen: !this.state.consultationOpen,
      dialogOpen: !this.state.dialogOpen,
    });
  };

  toggleOrg = () => {
    this.setState({
      dialogOpen: !this.state.dialogOpen,
      createOrgOpen: !this.state.createOrgOpen,
      dialogOpen: !this.state.dialogOpen,
    });
  };

  render() {
    const { open, consultationOpen, createOrgOpen, dialogOpen } = this.state;
    return (
      <div className={open ? "plus-menu open" : "plus-menu"}>
        <Fab onClick={this.toggleOpen}>
          <AddIcon style={{ fontSize: "35px" }} />
        </Fab>
        <div className={open ? "bg" : "bg close"}>
          <div className={"plus-menu--item"}>
            <p>Створити консультацію</p>
            <Fab onClick={this.toggleConsultation}>
              <Speach />
            </Fab>
          </div>
          <div className={"plus-menu--item"}>
            <p>Створити організацію</p>
            <Fab onClick={this.toggleOrg}>
              <Users />
            </Fab>
          </div>
          {/* <div className={'plus-menu--item'}>
            <p>Написати в саппорт</p>
            <Fab>
              <Question />
            </Fab>
          </div> */}
        </div>
        <Dialog fullScreen open={dialogOpen} onClose={this.toggleDialog}>
          {consultationOpen && (
            <ConsultationRequest
              onClose={this.toggleConsultation}
              action={(data) => CREATE_CONSULT(data)}
            />
          )}
          {createOrgOpen && (
            <CreateOrg
              onClose={this.toggleOrg}
              action={(data) => CREATE_ORG(data)}
            />
          )}
        </Dialog>
      </div>
    );
  }
}

export default withRouter(PlusMenu);
