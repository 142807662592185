import React from "react";
import CountUp from "react-countup";

function Statistics() {
  return (
    <div className="statistics">
      <div className="row">
        <div className="col col-xs-12 col-sm-6">
          <div className="statistics__item">
            <h1 className="statistics__title">
              Скільки
              <br /> <b className="statistics__features">Консультацій</b>
              <br /> Проводять?
            </h1>
            <div className="statistics__right">
              <div className="statistics__count statistics__count--top">
                <h2 className="statistics__counter">
                  +<CountUp start={0} end={18} delay={0.2} duration={1.8} />
                </h2>
                <p className="statistics__text">в розрізі департаментів</p>
              </div>
              <div className="statistics__count">
                <h2 className="statistics__counter">
                  +<CountUp start={0} end={18} delay={0.2} duration={1.8} />
                </h2>
                <p className="statistics__text">в розрізі районів</p>
              </div>
            </div>
          </div>
        </div>

        <div className="col col-xs-12 col-sm-6">
          <div className="statistics__item">
            <h1 className="statistics__title">
              Скільки
              <br /> <b className="statistics__features">звітів</b>
              <br /> сформовано
            </h1>
            <div className="statistics__right">
              <div className="statistics__count statistics__count--top">
                <h2 className="statistics__counter">
                  +<CountUp start={0} end={18} delay={0.2} duration={1.8} />
                </h2>
                <p className="statistics__text">в розрізі департаментів</p>
              </div>
              <div className="statistics__count">
                <h2 className="statistics__counter">
                  +<CountUp start={0} end={18} delay={0.2} duration={1.8} />
                </h2>
                <p className="statistics__text">в розрізі районів</p>
              </div>
            </div>
          </div>
        </div>

        <div className="col col-xs-12 col-sm-12">
          <div className="statistics__item">
            <h1 className="statistics__title">
              Скільки
              <br />{" "}
              <b className="statistics__features">консультацій/ заходів</b>
              <br /> ініційовано
            </h1>
            <div className="statistics__right">
              <div className="statistics__right-item statistics__right-item--top">
                <div className="statistics__count statistics__count--left">
                  <h2 className="statistics__counter">
                    +<CountUp start={0} end={18} delay={0.2} duration={1.8} />
                  </h2>
                  <p className="statistics__text">в розрізі громадських рад</p>
                </div>
                <div className="statistics__count">
                  <h2 className="statistics__counter">
                    +<CountUp start={0} end={18} delay={0.2} duration={1.8} />
                  </h2>
                  <p className="statistics__text">нуо</p>
                </div>
              </div>
              <div className="statistics__right-item">
                <div className="statistics__count statistics__count--left">
                  <h2 className="statistics__counter">
                    +<CountUp start={0} end={18} delay={0.2} duration={1.8} />
                  </h2>
                  <p className="statistics__text">
                    консультативно-дорадчих органів
                  </p>
                </div>
                <div className="statistics__count">
                  <h2 className="statistics__counter">
                    +<CountUp start={0} end={18} delay={0.2} duration={1.8} />
                  </h2>
                  <p className="statistics__text">осн</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col col-xs-12 col-sm-12">
          <div className="statistics__item">
            <h1 className="statistics__title">
              Скільки надано
              <br /> <b className="statistics__features">пропозицій</b>
              <br /> до питань
            </h1>
            <div className="statistics__right">
              <div className="statistics__right-item statistics__right-item--top">
                <div className="statistics__count statistics__count--left">
                  <h2 className="statistics__counter">
                    +<CountUp start={0} end={18} delay={0.2} duration={1.8} />
                  </h2>
                  <p className="statistics__text">в розрізі громадських рад</p>
                </div>
                <div className="statistics__count">
                  <h2 className="statistics__counter">
                    +<CountUp start={0} end={18} delay={0.2} duration={1.8} />
                  </h2>
                  <p className="statistics__text">нуо</p>
                </div>
              </div>
              <div className="statistics__right-item">
                <div className="statistics__count statistics__count--left">
                  <h2 className="statistics__counter">
                    +<CountUp start={0} end={18} delay={0.2} duration={1.8} />
                  </h2>
                  <p className="statistics__text">
                    консультативно-дорадчих органів
                  </p>
                </div>
                <div className="statistics__count">
                  <h2 className="statistics__counter">
                    +<CountUp start={0} end={18} delay={0.2} duration={1.8} />
                  </h2>
                  <p className="statistics__text">осн</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Statistics;
