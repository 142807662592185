import React, { Component, Fragment } from "react";
import * as propTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Fab from "@material-ui/core/Fab";
import { ReactComponent as Speach } from "../../assets/images/icons/speach.svg";
import { ReactComponent as Users } from "../../assets/images/icons/users.svg";
import { ReactComponent as UserFlag } from "../../assets/images/icons/user_flag.svg";
import { ReactComponent as UserLabel } from "../../assets/images/icons/user_label.svg";
import { ReactComponent as UserSpeach } from "../../assets/images/icons/user_speach.svg";
import { ReactComponent as Help } from "../../assets/images/icons/help.svg";
import { ReactComponent as UserOrg } from "../../assets/images/icons/user_org.svg";
import { ReactComponent as UserData } from "../../assets/images/icons/user_data.svg";
import NoAuthModal from "../../components/NoAuthModal/NoAuthModal.js";
import logo from "../../assets/images/Group.png";

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false, // было false
      modal: false,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.isAuthorized !== prevProps.isAuthorized ||
      this.props.user !== prevProps.user
    ) {
      if (!this.props.isAuthorized || !this.props.user) {
        this.setState({
          open: false, // было false
        });
      }
    }
  }
  // разкоментировать для активности панели
  menuOpen = () => {
    this.setState({
      open: true,
    });
  };

  menuClose = () => {
    this.setState({
      open: false,
    });
  };

  handleClick = (e) => {
    if (
      e.target !== e.currentTarget &&
      e.target.parentNode.parentNode.getAttribute("name") !== "tech"
    ) {
      this.menuClose();
      this.setState({ modal: true });
    }
  };

  handleClose = () => this.setState({ modal: false });

  render() {
    const { open, modal } = this.state;
    const { closeBtn } = this.props;
    return (
      <div
        name="tech"
        className={open ? "sidebar sidebar--open" : "sidebar"}
        onMouseEnter={this.menuOpen}
        onMouseLeave={closeBtn ? null : this.menuClose}
      >
        {modal && <NoAuthModal onClose={this.handleClose} />}
        <div
          className={
            open ? "sidebar__logo sidebar__logo--open" : "sidebar__logo"
          }
          onClick={
            this.props.user || this.props.isAuthorized ? this.toggleOpen : null
          }
        >
          {closeBtn && (
            <div className={"sidebar__closeBtnArea"} onClick={closeBtn}>
              <div className={"sidebar__closeBtn"}></div>
            </div>
          )}
          <Link to="/" className="sidebar__link">
            <label
              className={
                open
                  ? "sidebar__label sidebar__customLink--open"
                  : "sidebar__label sidebar__customLink"
              }
              style={{ marginTop: "2px" }}
            >
              До головної
            </label>
            <img src={logo} alt="logo" />
          </Link>
        </div>
        <div
          className={
            open ? "sidebar__menu" : "sidebar__menu sidebar__menu--closed"
          }
          onClick={
            this.props.user || this.props.isAuthorized ? null : this.handleClick
          }
        >
          <div>
            <Link
              to={this.props.user || this.props.isAuthorized ? "/profile" : "#"}
              className="sidebar__link"
              style={{ marginLeft: "4px" }}
            >
              <Fab>
                <UserData />
              </Fab>
              <label
                className={
                  open
                    ? "sidebar__label sidebar__customLink--open"
                    : "sidebar__label sidebar__customLink"
                }
                style={{ marginLeft: "8px" }}
              >
                Мої дані
              </label>
            </Link>
          </div>
          <div>
            <Link
              to={
                this.props.user || this.props.isAuthorized
                  ? "/organisations"
                  : "#"
              }
              className="sidebar__link"
            >
              <Fab>
                <UserOrg />
              </Fab>
              <label
                className={
                  open
                    ? "sidebar__label sidebar__customLink--open"
                    : "sidebar__label sidebar__customLink"
                }
                style={{ marginLeft: "12px" }}
              >
                Всі організації
              </label>
            </Link>
          </div>
          <div>
            <Link
              to={
                this.props.user || this.props.isAuthorized
                  ? "/my-organisations"
                  : "#"
              }
              className="sidebar__link"
            >
              <Fab>
                <UserOrg />
              </Fab>
              <label
                className={
                  open
                    ? "sidebar__label sidebar__customLink--open"
                    : "sidebar__label sidebar__customLink"
                }
                style={{ marginLeft: "12px" }}
              >
                Мої організації
              </label>
            </Link>
          </div>
          <div>
            <Link
              to={
                this.props.user || this.props.isAuthorized
                  ? "/other-organisations"
                  : "#"
              }
              className="sidebar__link"
              style={{ marginLeft: "4px" }}
            >
              <Fab>
                <Users />
              </Fab>
              <label
                className={
                  open
                    ? "sidebar__label sidebar__customLink--open"
                    : "sidebar__label sidebar__customLink"
                }
                style={{ marginLeft: "8px" }}
              >
                Інші організаціі
              </label>
            </Link>
          </div>
          <div>
            <Link
              to={
                this.props.user || this.props.isAuthorized
                  ? "/incoming-questions"
                  : "#"
              }
              className="sidebar__link"
              style={{ marginLeft: "5px" }}
            >
              <Fab>
                <Speach />
              </Fab>
              <label
                className={
                  open
                    ? "sidebar__label sidebar__customLink--open"
                    : "sidebar__label sidebar__customLink"
                }
                style={{ marginLeft: "7px" }}
              >
                Вхідні
              </label>
            </Link>
          </div>
          <div>
            <Link
              to={
                this.props.user || this.props.isAuthorized
                  ? "/my-questions"
                  : "#"
              }
              className="sidebar__link"
              style={{ marginLeft: "4px" }}
            >
              <Fab>
                <UserData />
              </Fab>
              <label
                className={
                  open
                    ? "sidebar__label sidebar__customLink--open"
                    : "sidebar__label sidebar__customLink"
                }
                style={{ marginLeft: "7px" }}
              >
                Мої запитання
              </label>
            </Link>
          </div>
          {/* <div name="tech">
            <Link
              name="tech"
              to="/"
              className="sidebar__link"
              style={{ marginLeft: "10px" }}
            >
              <Fab name="tech">
                <UserSpeach />
              </Fab>
              <label
                className={
                  open
                    ? "sidebar__label sidebar__customLink--open"
                    : "sidebar__label sidebar__customLink"
                }
                style={{ marginLeft: "2px" }}
              >
                Техпідтримка
              </label>
            </Link>
          </div>
          <Fab className={"sidebar__bottom"} style={{ marginLeft: "4px" }}>
            <Help />
          </Fab> */}
        </div>
      </div>
    );
  }
}

Sidebar.propTypes = {
  user: propTypes.object,
  isAuthorized: propTypes.bool,
};

function mapStateToProps({ auth }) {
  return {
    user: auth.user,
    isAuthorized: auth.isAuthorized,
  };
}

export default connect(mapStateToProps)(Sidebar);
