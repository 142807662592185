import {
	CLOSE_AUTH_PAGE,
	CREATE_ACC_STARTED, CREATE_ACC_SUCCESS,
	LOGIN_REQUEST_STARTED, LOGIN_REQUEST_SUCCESS, LOGOUT, REQUEST_FAILURE, SET_AUTH_PAGE
} from "../actions/types/auth";

export default function auth(state = {
	authPage: 'login',
	isOpen: false,
	loading: false,
	isAuthorized: false,
	user: null,
	error: null,
}, action) {
	switch (action.type) {
		case SET_AUTH_PAGE:
			return {...state, loading: false, error: null, isOpen: true, authPage: action.payload.page};
		case CLOSE_AUTH_PAGE:
			return {...state, loading: false, error: null, isOpen: false};
		case LOGIN_REQUEST_STARTED:
			return { ...state, loading: true, error: null};
		case LOGIN_REQUEST_SUCCESS:
			return { ...state, loading: false, user: action.payload.user, error: null, isAuthorized: true };
		case REQUEST_FAILURE:
			return { ...state, loading: false, error: action.payload.error };
		case LOGOUT:
			return { ...state, loading: false, error: null, user: null, token: undefined, isAuthorized: false };
		case CREATE_ACC_STARTED:
			return { ...state, loading: true, error: null};
		case CREATE_ACC_SUCCESS:
			return { ...state, loading: false, error: null, user: action.payload.user};
		default:
			return state;
	}
}
