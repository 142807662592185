import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import * as propTypes from "prop-types";
import { connect } from "react-redux";
import Input from "../../UI/inputs/CustomInput";
import TextArea from "../../UI/inputs/CustomTextArea";
import Select from "../../UI/inputs/CustomSelect";
import CustomMultiSelect from "../../UI/inputs/CustomMultiSelect";
import DragFile from "../../UI/inputs/DragFileInput";
import CalendarInput from "../../UI/inputs/CalendarInput";
import Grid from "@material-ui/core/Grid";
import {
  FETCH_FILTERS,
  FETCH_CONSULT_FILTERS,
} from "../../../actions/listsAction";
import {
  FETCH_ORG_GO,
  FETCH_ORG_GOV,
} from "../../../actions/organisationsActions";
import { ReactComponent as Send } from "../../../assets/images/icons/icon_send-white.svg";
//import { DevTool } from '@hookform/devtools';

function ConsultationRequestForm(props) {
  const { orgGO, orgGOV, consultFilters, cityAreas, type, consulation } = props;

  const {
    register,
    handleSubmit,
    setValue,
    errors,
    formState,
    control,
  } = useForm({
    defaultValues: {
      title: consulation ? consulation.title : "",
      event_id: consulation ? consulation.event_id : "",
      consultation_form_id: consulation ? consulation.consultation_form_id : "",
      description: consulation ? consulation.description : "",
      responsible_organisation_id: consulation
        ? consulation.responsible_organisation_id
        : "",
      areas: consulation ? consulation.areas.join(",") : "",
      subject_organisation_id: consulation
        ? consulation.subject_organisation_id
        : "",
      subject_id: consulation ? consulation.subject_id : "",
      partners: consulation ? consulation.partners.join(",") : null,
    },
  });

  const { isSubmitted } = formState;

  const [files, setFiles] = useState([]);

  useEffect(() => {
    props.dispatch(FETCH_ORG_GO());
    props.dispatch(FETCH_ORG_GOV());
    props.dispatch(FETCH_FILTERS());
    props.dispatch(FETCH_CONSULT_FILTERS());

    if (type) {
      register({ name: "dev_target" }, { required: true });
      register({ name: "legal_act_link" }, { required: true });
      register({ name: "discussion_start_date" }, { required: true });
      register({ name: "discussion_end_date" }, { required: true });
    } else {
      register({ name: "title" }, { required: true });
      // register({ name: 'event_id' }, { required: true })
      register({ name: "consultation_form_id" }, { required: true });
      register({ name: "description" }, { required: true });
      register({ name: "responsible_organisation_id" }, { required: true });
      register({ name: "areas" }, { required: true });
      register({ name: "subject_organisation_id" }, { required: true });
      register({ name: "subject_id" }, { required: true });
      register({ name: "partners" });
    }
  }, []);

  const handleChange = (evt) => {
    setValue(evt.target.name, evt.target.value);
  };

  const handleFiles = (files) => {
    setFiles(files);
  };

  const submitForm = (data) => {
    if (type) {
      let consulation = {
        dates: {
          discussion_start_date: data.discussion_start_date,
          discussion_end_date: data.discussion_end_date,
        },
        dev_target: data.dev_target,
        legal_act_link: data.legal_act_link,
      };
      props.onSubmit(consulation);
    } else {
      let consulation = {
        info: {
          title: data.title,
          description: data.description,
          subject_id: data.subject_id,
        },
        event: {
          consultation_form_id: data.consultation_form_id,
          event_id: data.event_id,
        },
        responsibilities: {
          responsible_organisation_id: data.responsible_organisation_id,
          subject_organisation_id: data.subject_organisation_id,
        },
        partners: data.partners && data.partners.split(","),
        areas: [data.areas],
        files: files.length ? files : undefined,
      };
      props.onSubmit(consulation);
    }
  };

  return (
    <form className="consultation__form" onSubmit={handleSubmit(submitForm)}>
      {/* <DevTool control={control} /> */}

      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Input
            placeholder="Назва (питання або проекту НПА)*"
            name="title"
            register={register}
            handleChange={handleChange}
            errors={errors}
            isSubmitted={isSubmitted}
            disabled={type ? true : false}
          />
        </Grid>
        <Grid container spacing={0}>
          <Grid container spacing={0} item xs={12}>
            {/* <Grid item xs={12} md={6}>
              <Select
                placeholder="Захід*"
                name="event_id"
                register={register}
                handleChange={handleChange}
                errors={errors}
                isSubmitted={isSubmitted}
                data={consultFilters.events}
                type={type && type}
                field={consulation && consulation.event}
                disabled={type ? true : false}
              />
            </Grid> */}

            <Grid item xs={12} md={12}>
              <Select
                placeholder="Форма консультації*"
                name="consultation_form_id"
                register={register}
                handleChange={(val) => setValue("consultation_form_id", val.id)}
                errors={errors}
                isSubmitted={isSubmitted}
                data={consultFilters.consultsForm}
                type={type && type}
                field={consulation && consulation.consultationForm}
                disabled={type ? true : false}
              />
            </Grid>
            <Grid item xs={12}>
              <TextArea
                placeholder="Опишіть, будь ласка, своє запитання / пропозицію...*"
                name="description"
                register={register}
                handleChange={handleChange}
                errors={errors}
                isSubmitted={isSubmitted}
                disabled={type ? true : false}
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <DragFile
              disabled={type ? true : false}
              handleFiles={handleFiles}
            />
          </Grid>
        </Grid>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <Select
              placeholder="Відповідальний структурний підрозділ*"
              name="responsible_organisation_id"
              register={register}
              handleChange={(val) =>
                setValue("responsible_organisation_id", val.id)
              }
              errors={errors}
              isSubmitted={isSubmitted}
              data={orgGOV}
              type={type && type}
              field={consulation && consulation.responsibleOrganisation}
              disabled={type ? true : false}
            />
          </Grid>
          <Grid item xs={12}>
            <Select
              placeholder="Район*"
              name="areas"
              register={register}
              handleChange={(val) => setValue("areas", val.id)}
              errors={errors}
              isSubmitted={isSubmitted}
              data={cityAreas}
              type={type && type}
              field={consulation && consulation.areas}
              disabled={type ? true : false}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Select
              placeholder="Суб'єкт подання*"
              name="subject_organisation_id"
              register={register}
              handleChange={(val) =>
                setValue("subject_organisation_id", val.id)
              }
              errors={errors}
              isSubmitted={isSubmitted}
              data={orgGO}
              type={type && type}
              field={consulation && consulation.subjectOrganisation}
              disabled={type ? true : false}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CustomMultiSelect
              placeholder="Співвиконавці"
              name="partners"
              register={register}
              handleChange={handleChange}
              errors={errors}
              isSubmitted={isSubmitted}
              data={orgGO}
              type={type && type}
              field={consulation && consulation.partners}
              disabled={type ? true : false}
            />
          </Grid>
          <Grid item xs={12}>
            <Select
              placeholder="Тема*"
              name="subject_id"
              register={register}
              //  handleChange={handleChange}
              handleChange={(val) => setValue("subject_id", val.id)}
              errors={errors}
              isSubmitted={isSubmitted}
              data={consultFilters.thematics}
              type={type && type}
              field={consulation && consulation.subject}
              disabled={type ? true : false}
            />
          </Grid>
        </Grid>
        {type && (
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <Input
                placeholder="Мета розробки*"
                name="dev_target"
                register={register}
                handleChange={handleChange}
                errors={errors}
                isSubmitted={isSubmitted}
              />
            </Grid>
            <Grid item xs={12}>
              <Input
                placeholder="Посилання на НПА*"
                name="legal_act_link"
                register={register}
                handleChange={handleChange}
                errors={errors}
                isSubmitted={isSubmitted}
              />
            </Grid>
            <Grid item xs={12}>
              <CalendarInput
                placeholder="Дата початку*"
                name="discussion_start_date"
                register={register}
                handleChange={handleChange}
                errors={errors}
                isSubmitted={isSubmitted}
              />
            </Grid>
            <Grid item xs={12}>
              <CalendarInput
                placeholder="Дата закінчення*"
                name="discussion_end_date"
                register={register}
                handleChange={handleChange}
                errors={errors}
                isSubmitted={isSubmitted}
              />
            </Grid>
          </Grid>
        )}
      </Grid>
      <button className="button-send">
        <span>ВІДПРАВИТИ</span>
        <Send />
      </button>
    </form>
  );
}

ConsultationRequestForm.propTypes = {
  onSubmit: propTypes.func,
  type: propTypes.string,
};

function mapStateToProps({ organisations, lists, consulations }) {
  return {
    orgGO: organisations.orgGO,
    orgGOV: organisations.orgGOV,
    consultFilters: lists.consultFilters,
    cityAreas: lists.filters.cityAreas,
    consulation: consulations.consulation,
  };
}

export default connect(mapStateToProps)(ConsultationRequestForm);
