import React, { useState, useEffect } from 'react'
import * as propTypes from 'prop-types'
import { useDropzone } from 'react-dropzone'
import { ReactComponent as FileUpload } from '../../../assets/images/icons/upload.svg'

function DragFileInput (props) {

  const [ uploadFiles, setUploadFiles ] = useState([])

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    onDrop: acceptedFiles => {
      setUploadFiles(acceptedFiles.map(file => {
        return Object.assign(file, {
          preview: URL.createObjectURL(file)
        })
      }))
    }
  })

  const getBase64 = () => {
    const base64Arr = []
    const setBase64Arr = () => {
      return new Promise((resolve, reject) => {
        uploadFiles.forEach(file => {
          const reader = new FileReader()
          reader.readAsDataURL(file)
          reader.onload = function () {
            base64Arr.push(reader.result)
            resolve()
          }
          reader.onerror = function (error) {
            reject()
          }
        })
      })
    }
    setBase64Arr()
      .then(() => {
        props.handleFiles(base64Arr)
      })
  }

  const removePic = (evt, file) => {
    evt.stopPropagation()
    uploadFiles.splice(uploadFiles.indexOf(file), 1)
    setUploadFiles(
      [...uploadFiles]
    )
  }

  useEffect(() => {
    uploadFiles.forEach(file => URL.revokeObjectURL(file.preview))
    getBase64()
  }, [uploadFiles])

  return (
    <div style={{'paddingBottom': '20px'}}>
      <div {...getRootProps({className: 'dropzone'})} className={`drag-file ${props.disabled && 'input-disabled'}`}>
        <div className="drag-file__inner">
          <input disabled={props.disabled} {...getInputProps()} />
          {uploadFiles.length > 0 &&
            <div className="drag-file__inner-top">
              {uploadFiles.map(file => {
                return (
                  <div key={file.preview} className="drag-file__pic">
                    <img style={{'borderRadius': '5px 35px 5px 5px'}} width="56" height="74" src={file.preview} />
                    <div className="drag-file__pic-remove">
                      <span onClick={(evt) => removePic(evt, file)} className="material-icons remove-pic">clear</span>
                    </div>
                  </div>
                )
              })
              }
            </div> 
          }
          <div className="drag-file__info">
            <FileUpload className="drag-file__icon" />
            <p className="drag-file__text">Перетягніть файли, щоб завантажити їх.</p>
          </div>
        </div>
      </div>
    </div>
  )
}

DragFileInput.propTypes = {
  handleFiles: propTypes.func,
  disabled: propTypes.bool
}

export default DragFileInput
