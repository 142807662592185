import {
  GET_FILTERS,
  GET_CONSULT_FILTERS
} from '../actions/types/lists'

const initialState = {
  filters: {},
  consultFilters: {}
}

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_FILTERS:
      return {
        ...state,
        filters: action.payload
      }
    case GET_CONSULT_FILTERS:
      return {
        ...state,
       consultFilters: action.payload
      }
    default:
      return state
  }
}
