import React from 'react'
import propTypes from 'prop-types'
import IconButton from '@material-ui/core/IconButton'
import Table from '../tables/TableCandidates'
import logo from '../../../assets/images/color_logo.svg'
import { ReactComponent as CloseIcon } from '../../../assets/images/icons/close-white.svg'

function CandidatesInOrg (props) {
  return (
    <div className="candidates">
      <div className="candidates__top">
        <img className="candidates__logo" src={logo} alt="logo" />
        <h1 className="candidates__title">Запити нових учасників</h1>
        <div className="candidates__icon">
          <IconButton edge="start" color="inherit" onClick={props.onClose} aria-label="close">
            <CloseIcon style={{ width: '26px', height: '26px' }} />
          </IconButton>
        </div>
      </div>
      <Table orgId={props.orgId} data={props.data} />
    </div>
  )
}

CandidatesInOrg.propTypes = {
  data: propTypes.arrayOf(propTypes.object).isRequired,
  onClose: propTypes.func.isRequired,
  orgId: propTypes.number.isRequired
}

export default CandidatesInOrg
