import axiosAuth from "../config/axiosAuthConfig";
import { enqueueSnackbar as enqueueSnackbarAction } from "./snackBarActions";

export const UPDATE_USER_PROFILE = (data) => (dispatch) => {
  const enqueueSnackbar = (...args) => dispatch(enqueueSnackbarAction(...args));
  return new Promise((resolve, reject) => {
    axiosAuth
      .patch("profile/edit", data)
      .then((res) => {
        enqueueSnackbar({
          message: "Ви оновили профіль",
          options: {
            variant: "success",
          },
        });
        resolve();
      })
      .catch((err) => {
        enqueueSnackbar({
          message: err.response.data.result.message,
          options: {
            variant: "error",
          },
        });
        reject();
      });
  });
};
