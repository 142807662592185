import React, { useState, useEffect } from "react";
import * as propTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Pagination from "@material-ui/lab/Pagination";

const useStyles = makeStyles(() => ({
  root: {},
}));

function CustomPagination(props) {
  const classes = useStyles();

  const [page, setPage] = useState(null);

  const [pagination, setPagination] = useState({});

  useEffect(() => {
    setPagination(props ? props.pagination : []);
    setPage(props && props.pagination ? props.pagination.currentPage : null);
  }, [props && props.pagination]);

  const handleChange = (event, value) => {
    setPage(value);
    props.fetchData({ currentPage: value });
  };

  return (
    <div className={props.variant}>
      {Object.keys(pagination || {}).length && (
        <Pagination
          size="large"
          page={page}
          count={pagination.pageCount}
          onChange={handleChange}
        />
      )}
    </div>
  );
}

CustomPagination.propTypes = {
  fetchData: propTypes.func.isRequired,
  pagination: propTypes.object.isRequired,
  variant: propTypes.string,
};

export default CustomPagination;
