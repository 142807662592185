import React, { useState, useEffect } from "react";
import * as propTypes from "prop-types";

function Autocomplete(props) {
  const [filteredDatas, setFilteredDatas] = useState([]);

  const [selectActivity, setSelectActivity] = useState([]);

  const [value, setValue] = useState("");

  const [open, setOpen] = useState(false);

  const {
    placeholder,
    name,
    register,
    handleChange,
    errors,
    isSubmitted,
    data,
    type,
    field,
    passData,
    queryParams,
  } = props;

  const getSelectItem = () => {
    let tempArr = [];
    if (type && field) {
      tempArr = data.filter((item) =>
        field.split(",").includes(item.name || item.title)
      );
    } else {
      tempArr = data.filter((item) =>
        queryParams.split(",").includes(item.id.toString())
      );
    }
    if (tempArr[0]) {
      setSelectActivity([...tempArr]);
    }
  };

  useEffect(() => {
    if (type && field) {
      getSelectItem();
    }
  }, [data]);

  useEffect(() => {
    handleChange && handleChange(selectActivity);
    if (passData) {
      passData(selectActivity.map((item) => item.id).join(","));
    }
  }, [selectActivity]);

  useEffect(() => {
    if (queryParams) {
      getSelectItem();
    }
  }, [queryParams]);

  const filterData = (evt) => {
    if (evt.target.value) {
      setOpen(true);
      let tempArr = data.filter((item) => {
        return item.name.toLowerCase().includes(evt.target.value.toLowerCase());
      });
      setFilteredDatas(tempArr);
    } else {
      setOpen(false);
      setFilteredDatas([]);
    }
  };

  const handleInput = (evt) => {
    setValue(evt.target.value);
  };

  const addActivity = (activity) => {
    if (!selectActivity.some((item) => item.id === activity.id)) {
      setSelectActivity([...selectActivity, activity]);
    } else {
      selectActivity.splice(selectActivity.indexOf(activity), 1);
      setSelectActivity([...selectActivity]);
    }
  };

  const removeActivity = (activity) => {
    selectActivity.splice(selectActivity.indexOf(activity), 1);
    setSelectActivity([...selectActivity]);
  };

  const closeAutocomplete = (item) => {
    addActivity(item);
    setOpen(false);
    setValue("");
  };

  return (
    <div className="autocomplete">
      <input
        type="text"
        placeholder={placeholder}
        ref={register}
        name={name}
        value={selectActivity.map((item) => item.id).join(",")}
        disabled
        onChange={handleChange}
        style={{ display: "none" }}
      />

      <input
        type="text"
        value={value}
        onChange={handleInput}
        placeholder={placeholder}
        onInput={filterData}
        className={`autocomplete__field ${
          !selectActivity.length > 0 && errors && errors[name]
            ? "input-error"
            : ""
        } ${isSubmitted && !errors[name] ? "input-valid" : ""}`}
      />
      {filteredDatas && filteredDatas.length > 0 && open && (
        <div className="autocomplete__inner">
          {filteredDatas.map((item) => {
            return (
              <div
                key={item.id}
                className="autocomplete__item"
                onClick={() => closeAutocomplete(item)}
              >
                {item.name}
              </div>
            );
          })}
        </div>
      )}
      {selectActivity.length > 0 && (
        <div className="autocomplete__bottom">
          {selectActivity.map((item) => {
            return (
              <div key={item.id} className="autocomplete__selected">
                <span>#{item.name}</span>
                <span
                  onClick={() => removeActivity(item)}
                  className="material-icons autocomplete__icon"
                >
                  clear
                </span>
              </div>
            );
          })}
        </div>
      )}
      {!selectActivity.length > 0 &&
        errors &&
        errors[name] &&
        errors[name].type === "required" && (
          <span className="input-error-text">Будь ласка, заповніть поле.</span>
        )}
    </div>
  );
}

Autocomplete.propTypes = {
  placeholder: propTypes.string,
  name: propTypes.string,
  register: propTypes.func,
  handleChange: propTypes.func,
  errors: propTypes.object,
  isSubmitted: propTypes.bool,
  data: propTypes.arrayOf(propTypes.object),
  type: propTypes.string,
  field: propTypes.string,
  passData: propTypes.func,
  queryParams: propTypes.string,
};

export default Autocomplete;
