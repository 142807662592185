import React, {Component} from "react";
import IconButton from "@material-ui/core/IconButton";
import {ReactComponent as CloseIcon} from "../../assets/images/icons/close.svg";
import logo from '../../assets/images/sidebar_logo.svg';
import uaLocale from "date-fns/locale/uk";
import {ReactComponent as RegisterBg} from "../../assets/images/icons/login_button.svg";
import Fab from "@material-ui/core/Fab";
import {ReactComponent as LogIn} from "../../assets/images/icons/login_blue_icon.svg";
import {connect} from "react-redux";
import * as propTypes from "prop-types";
import {closeAuth, setAuthPage} from "../../actions/authActions";
import UpdateForm from "./forms/UpdateForm";
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

// pick a date util library
import DateFnsUtils from '@date-io/date-fns';

class Update extends Component{
	constructor(props) {
		super(props);
	}

	render() {
		const {dispatch} = this.props;
		return(
			<div className={'register'}>
				<div className={'register-close'}>
					<IconButton edge="start" color="inherit" onClick={() => dispatch(closeAuth())} aria-label="close">
						<CloseIcon style={{width: '26px', height: '26px'}}/>
					</IconButton>
				</div>
				<div className={'register-body'}>
					<img src={logo} alt=""/>
					<h2>Створити аккаунт</h2>
					<MuiPickersUtilsProvider utils={DateFnsUtils} locale={uaLocale}>
						<UpdateForm/>
					</MuiPickersUtilsProvider>
					<div className={'move-to'}>
						<h3>У вас вже є аккаунт?</h3>
						<p>Натисніть кнопку нижче, щоб увійти.</p>
						<div className={'move-to--btn'}>
							<RegisterBg/>
							<Fab onClick={() => dispatch(setAuthPage('login'))}>
								<LogIn/>
							</Fab>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

Update.propTypes = {
	dispatch: propTypes.func.isRequired,
	loading: propTypes.bool
};

function mapStateToProps({auth}) {
	return {
		loading: auth.loading
	}
}

export default connect(mapStateToProps)(Update);
