import {
  GET_ORG,
  REFETCH_ORG,
  ERROR_ORG,
  GET_ORG_GO,
  GET_ORG_GOV,
  GET_USER_ORG_MEMBER
} from '../actions/types/organisations'

const initialState = {
  organisations: {},
  orgGO: [],
  orgGOV: [],
  orgMember: [],
  loading: false,
  error: false
}

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_ORG:
      return {
        ...state,
        organisations: action.payload,
        loading: true,
        error: false
      }
    case REFETCH_ORG:
      return {
        ...state,
        loading: false,
        error: false
      }
    case ERROR_ORG:
      return {
        ...state,
        organisations: {},
        loading: true,
        error: true
      }
    case GET_ORG_GO:
      return {
        ...state,
        orgGO: action.payload
      }
    case GET_ORG_GOV:
      return {
        ...state,
        orgGOV: action.payload
      }
    case GET_USER_ORG_MEMBER:
      return {
        ...state,
        orgMember: action.payload
      }
    default:
      return state
  }
}
