import axios from "../config/axiosConfig";
import axiosAuth from "../config/axiosAuthConfig";

import {
  GET_ORG,
  REFETCH_ORG,
  ERROR_ORG,
  GET_ORG_DETAILS,
  REFETCH_ORG_DETAILS,
  GET_USER_ORG,
  REFETCH_USER_ORG,
  ERROR_USER_ORG,
  GET_USER_ORG_REQ,
  REFETCH_USER_ORG_REQ,
  ERROR_USER_ORG_REQ,
  GET_ORG_GO,
  GET_ORG_GOV,
  GET_USER_ORG_MEMBER,
} from "./types/organisations";
import { enqueueSnackbar as enqueueSnackbarAction } from "./snackBarActions";

export const CREATE_ORG = (data) => (dispatch) => {
  const enqueueSnackbar = (...args) => dispatch(enqueueSnackbarAction(...args));
  return new Promise((resolve, reject) => {
    axiosAuth
      .post("organisation", data)
      .then((res) => {
        enqueueSnackbar({
          message: "Ви створили організацію",
          options: {
            variant: "success",
          },
        });
        resolve();
      })
      .catch((err) => {
        enqueueSnackbar({
          message: err.response.data.result.message,
          options: {
            variant: "error",
          },
        });
        reject();
      });
  });
};

export const FETCH_ORG_DETAILS = (id) => (dispatch) => {
  dispatch({
    type: REFETCH_ORG_DETAILS,
  });
  const enqueueSnackbar = (...args) => dispatch(enqueueSnackbarAction(...args));
  return new Promise((resolve, reject) => {
    Promise.all([
      axios.get(`organisation/${id}?expand=members`),
      axios.get("questions", {
        params: {
          subject_organisation_id: id,
        },
      }),
    ])
      .then((value) => {
        dispatch({
          type: GET_ORG_DETAILS,
          payload: {
            organisation: value[0].data.result,
            questions: value[1].data.result,
          },
        });
        resolve();
      })
      .catch((err) => {
        enqueueSnackbar({
          message: err.response.data.result.message,
          options: {
            variant: "error",
          },
        });
        reject();
      });
  });
};

export const FETCH_ORG = (filters) => (dispatch) => {
  const enqueueSnackbar = (...args) => dispatch(enqueueSnackbarAction(...args));
  dispatch({
    type: REFETCH_ORG,
  });
  axios
    .get("organisations?sort=-id&expand=members,candidates", {
      params: filters,
    })
    .then((res) => {
      dispatch({
        type: GET_ORG,
        payload: {
          data: res.data.result,
          pagination: res.data._meta,
        },
      });
    })
    .catch((err) => {
      dispatch({
        type: ERROR_ORG,
      });
      enqueueSnackbar({
        message: err.response.data.result.message,
        options: {
          variant: "error",
        },
      });
    });
};

export const FETCH_USER_ORG = (filters) => (dispatch) => {
  const enqueueSnackbar = (...args) => dispatch(enqueueSnackbarAction(...args));
  if (filters) {
    dispatch({
      type: REFETCH_USER_ORG,
    });
  }
  axiosAuth
    .get("own-organisations?sort=-id&expand=candidates", {
      params: filters,
    })
    .then((res) => {
      dispatch({
        type: GET_USER_ORG,
        payload: {
          data: res.data.result,
          pagination: res.data._meta,
        },
      });
    })
    .catch((err) => {
      dispatch({
        type: ERROR_USER_ORG,
      });
      enqueueSnackbar({
        message: err.response.data.result.message,
        options: {
          variant: "error",
        },
      });
    });
};

export const JOIN_TO_ORG = (data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    axiosAuth
      .post("join-to-organisations", data)
      .then((res) => {
        resolve();
      })
      .catch((err) => {
        reject();
      })
      .catch((err) => {
        reject();
      });
  });
};

export const ACCEPT_CANDIDATE = (id, data) => (dispatch) => {
  const enqueueSnackbar = (...args) => dispatch(enqueueSnackbarAction(...args));
  return new Promise((resolve, reject) => {
    axiosAuth
      .post(`accept-candidates/${id}`, data)
      .then((res) => {
        enqueueSnackbar({
          message: "Кандидат прийнят",
          options: {
            variant: "success",
          },
        });
        resolve();
      })
      .catch((err) => {
        enqueueSnackbar({
          message: err.response.data.result.message,
          options: {
            variant: "error",
          },
        });
        reject();
      });
  });
};

export const REFUSE_CANDIDATE = (id, data) => (dispatch) => {
  const enqueueSnackbar = (...args) => dispatch(enqueueSnackbarAction(...args));
  return new Promise((resolve, reject) => {
    axiosAuth
      .post(`organisation/refuse-candidate/${id}`, data)
      .then((res) => {
        enqueueSnackbar({
          message: "Кандидат відхилен",
          options: {
            variant: "success",
          },
        });
        resolve();
      })
      .catch((err) => {
        enqueueSnackbar({
          message: err.response.data.result.message,
          options: {
            variant: "error",
          },
        });
        reject();
      });
  });
};

export const FETCH_USER_ORG_REQ = (filters) => (dispatch) => {
  const enqueueSnackbar = (...args) => dispatch(enqueueSnackbarAction(...args));
  dispatch({
    type: REFETCH_USER_ORG_REQ,
  });
  axiosAuth
    .get("related-organisations?sort=-id&expand=isMember,isCandidate", {
      params: filters,
    })
    .then((res) => {
      dispatch({
        type: GET_USER_ORG_REQ,
        payload: {
          data: res.data.result,
          pagination: res.data._meta,
        },
      });
    })
    .catch((err) => {
      dispatch({
        type: ERROR_USER_ORG_REQ,
      });
      enqueueSnackbar({
        message: err.response.data.result.message,
        options: {
          variant: "error",
        },
      });
    });
};

export const FETCH_ORG_GO = () => (dispatch) => {
  const enqueueSnackbar = (...args) => dispatch(enqueueSnackbarAction(...args));
  axios
    .get("organisations?group_type=20")
    .then((res) => {
      let tempArr = res.data.result.map((item) => {
        item.checked = false;
        return item;
      });
      dispatch({
        type: GET_ORG_GO,
        payload: tempArr,
      });
    })
    .catch((err) => {
      enqueueSnackbar({
        message: err.response.data.result.message,
        options: {
          variant: "error",
        },
      });
    });
};

export const FETCH_ORG_GOV = () => (dispatch) => {
  const enqueueSnackbar = (...args) => dispatch(enqueueSnackbarAction(...args));
  axios
    .get("organisations?group_type=10")
    .then((res) => {
      dispatch({
        type: GET_ORG_GOV,
        payload: res.data.result,
      });
    })
    .catch((err) => {
      enqueueSnackbar({
        message: err.response.data.result.message,
        options: {
          variant: "error",
        },
      });
    });
};

export const FETCH_USER_ORG_MEMBER = (filters) => (dispatch) => {
  const enqueueSnackbar = (...args) => dispatch(enqueueSnackbarAction(...args));
  axiosAuth
    .get("my-organisations")
    .then((res) => {
      dispatch({
        type: GET_USER_ORG_MEMBER,
        payload: res.data.result.map((item) => item.id).join(","),
      });
    })
    .catch((err) => {
      enqueueSnackbar({
        message: err.response.data.result.message,
        options: {
          variant: "error",
        },
      });
    });
};

export const EDIT_ORG = (id, data) => (dispatch) => {
  const enqueueSnackbar = (...args) => dispatch(enqueueSnackbarAction(...args));
  return new Promise((resolve, reject) => {
    axiosAuth
      .patch(`organisation/${id}`, data)
      .then((res) => {
        enqueueSnackbar({
          message: "Ви оновили організацію",
          options: {
            variant: "success",
          },
        });
        resolve();
      })
      .catch((err) => {
        enqueueSnackbar({
          message: err.response.data.result.message,
          options: {
            variant: "error",
          },
        });
        reject();
      });
  });
};
