import axios from "axios";
import { config } from "../env/enviroment";

const QUESTION_SEARCH = (title) =>
  axios
    .get(`${config.baseURL}questions?title=${title}`)
    .then((response) => console.log(response.data))
    .catch((error) => console.log(error));

const ORGANISATION_SEARCH = (title) =>
  axios
    .get(`${config.baseURL}organisations?title=${title}`)
    .then((response) => console.log(response.data))
    .catch((error) => console.log(error));

export default { QUESTION_SEARCH, ORGANISATION_SEARCH };
