import axiosAuth from "../config/axiosAuthConfig";
import {
  GET_CONSULT,
  REFETCH_CONSULT,
  GET_CONSULT_DETAILS,
  REFETCH_CONSULT_DETAILS,
  ERROR_CONSULT,
} from "./types/consulations";

import { enqueueSnackbar as enqueueSnackbarAction } from "./snackBarActions";

export const CREATE_CONSULT = (data) => (dispatch) => {
  const enqueueSnackbar = (...args) => dispatch(enqueueSnackbarAction(...args));
  return new Promise((resolve, reject) => {
    axiosAuth
      .post("question", data)
      .then((res) => {
        enqueueSnackbar({
          message: "Ви створили запитання",
          options: {
            variant: "success",
          },
        });
        resolve();
      })
      .catch((err) => {
        enqueueSnackbar({
          message: err.response.data.result.message,
          options: {
            variant: "error",
          },
        });
        reject();
      });
  });
};

export const FETCH_USER_CONSULT = (filters) => (dispatch) => {
  const enqueueSnackbar = (...args) => dispatch(enqueueSnackbarAction(...args));
  dispatch({
    type: REFETCH_CONSULT,
  });
  axiosAuth
    .get("questions?sort=-id", {
      params: filters,
    })
    .then((res) => {
      let tempArr = res.data.result.map((item) => {
        item.isOpen = false;
        return item;
      });
      dispatch({
        type: GET_CONSULT,
        payload: {
          data: tempArr,
          pagination: res.data._meta,
        },
      });
    })
    .catch((err) => {
      dispatch({
        type: ERROR_CONSULT,
      });
      enqueueSnackbar({
        message: err.response.data.result.message,
        options: {
          variant: "error",
        },
      });
    });
};

export const FETCH_CONSULT_DETAILS = (id) => (dispatch) => {
  dispatch({
    type: REFETCH_CONSULT_DETAILS,
  });
  const enqueueSnackbar = (...args) => dispatch(enqueueSnackbarAction(...args));
  axiosAuth
    .get(`question/${id}?expand=areas,partners`)
    .then((res) => {
      dispatch({
        type: GET_CONSULT_DETAILS,
        payload: res.data.result,
      });
    })
    .catch((err) => {
      enqueueSnackbar({
        message: err.response.data.result.message,
        options: {
          variant: "error",
        },
      });
    });
};

export const ACCEPT_CONSULT = (id, data) => (dispatch) => {
  const enqueueSnackbar = (...args) => dispatch(enqueueSnackbarAction(...args));
  return new Promise((resolve, reject) => {
    axiosAuth
      .patch(`question/accept/${id}`, data)
      .then((res) => {
        enqueueSnackbar({
          message: "Ви оновили запитання",
          options: {
            variant: "success",
          },
        });
        resolve();
      })
      .catch((err) => {
        enqueueSnackbar({
          message: err.response.data.result.message,
          options: {
            variant: "error",
          },
        });
        reject();
      });
  });
};
