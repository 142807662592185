import React, { useState, useEffect, Fragment } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import CountUp from "react-countup";
import CircularProgress from "@material-ui/core/CircularProgress";
import ConsultFilters from "../../containers/ConsultFilters";
import MainHeader from "../../containers/MainHeader";
import Pagination from "../../UI/Pagination";
import TableConsult from "../../containers/tables/TableConsults";
import { approveAcc } from "../../../actions/authActions";
import { FETCH_USER_CONSULT } from "../../../actions/cunsulationActions";
import { ReactComponent as Users } from "../../../assets/images/icons/users_grey.svg";

const headers = [
  "ID",
  "Назва",
  "Захід",
  "Структурний підрозділ",
  "Тема",
  "Строки проведення",
  "Статус",
  "",
];

const useStyles = makeStyles({
  root: {
    color: "#992F87",
  },
});

function UserConsults(props) {
  const classes = useStyles();

  const [filters, setFilters] = useState({});

  const [pagination, setPagination] = useState({});

  useEffect(() => {
    const { dispatch, match } = props;
    if (match.params.token) {
      dispatch(approveAcc(match.params.token));
    }
  }, []);

  const fetchData = (data) => {
    setFilters({
      ...filters,
      ...data,
    });
    props.dispatch(FETCH_USER_CONSULT({ ...data, ...pagination }));
  };

  const fetchDataWithPagination = (data) => {
    setPagination({
      ...pagination,
      ...data,
    });
    props.dispatch(FETCH_USER_CONSULT({ ...data, ...filters }));
  };

  return (
    <div className="main">
      <MainHeader />
      <div className="main__scrollable">
        <div className="main__count">
          <div>
            <h2 className="main__caption">Запитання</h2>
          </div>
          <div className="main__counter">
            <CountUp start={0} end={12355} delay={0.1} duration={1.9} />
            <Users />
          </div>
        </div>
        <ConsultFilters fetchData={fetchData} />
        {!props.loading ? (
          <div className="table-wrapper">
            <CircularProgress className={classes.root}></CircularProgress>
          </div>
        ) : (
          <Fragment>
            <TableConsult data={props.consulations} headers={headers} />
            <Pagination
              variant="pagination"
              fetchData={fetchDataWithPagination}
              pagination={props.pagination}
            />
          </Fragment>
        )}
      </div>
    </div>
  );
}

function mapStateToProps({ auth, consulations }) {
  return {
    consulations: consulations.consulations.data,
    loading: consulations.loading,
    pagination: consulations.consulations.pagination,
  };
}

export default connect(mapStateToProps)(UserConsults);
