import React, { Component } from "react";
import Login from "./logIn";
import Register from "./Register";
import { withSnackbar } from "notistack";
import * as propTypes from "prop-types";
import { connect } from "react-redux";
import CheckEmail from "./CheckEmail";
import Approve from "./Approve";
import Update from "./Update";

class Auth extends Component {
  constructor(props) {
    super(props);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { error, enqueueSnackbar } = this.props;
    if (error) {
      Object.values(error).forEach(i => {
        enqueueSnackbar(Array.isArray(i) ? i[0] : i, { variant: 'error' });
      })
    }
  }

  render() {
    const { authPage } = this.props;
    switch (authPage) {
      case 'login':
        return (
          <Login />
        );
      case 'register':
        return (
          !localStorage.getItem('access_token') ? <Register /> : <Update />
        );
      case 'checkEmail':
        return (
          <CheckEmail />
        );
      case 'approve':
        return (
          <Approve />
        );
      case 'update':
        return (
          <Update />
        );
      default:
        return (
          <Login />
        );
    }
  }
}

Auth.propTypes = {
  dispatch: propTypes.func.isRequired,
  token: propTypes.string,
  user: propTypes.object,
  authPage: propTypes.string,
  authOpen: propTypes.bool,
  error: propTypes.object
};

function mapStateToProps({ auth }) {
  return {
    token: auth.token,
    user: auth.user,
    authPage: auth.authPage,
    authOpen: auth.isOpen,
    error: auth.error
  }
}

export default connect(mapStateToProps)(withSnackbar(Auth));
