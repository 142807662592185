import React, { useState, Fragment } from 'react'
import * as propTypes from 'prop-types'
import { ReactComponent as Shevron } from '../../../assets/images/icons/shevron_bottom.svg'

function Select(props) {
  const [open, setOpen] = useState(false)

  const handleClick = (evt) => {
    setOpen(!open)
  }

  const selectItem = (evt, val) => {
    setOpen(!open)
    props.handleChange(evt, val)
  }

  return (
    <Fragment>
      <div className="table-select">
        <span>Оберіть варіант</span>
        <Shevron style={{ cursor: 'pointer' }} onClick={handleClick} />
      </div>
      {open && (
        <div className="table-select__inner">
          {
            props.data.map((item, index) => {
              return (
                <div key={index} id={props.itemId} onClick={(evt) => selectItem(evt, item.value)} className="table-select__item">
                  {item.name}
                </div>
              )
            })
          }
        </div>
      )}
    </Fragment>
  )
}

Select.propTypes = {
  data: propTypes.arrayOf(propTypes.object),
  handleChange: propTypes.func.isRequired,
  itemId: propTypes.number.isRequired
}

export default Select
