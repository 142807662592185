import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import * as propTypes from 'prop-types'
import qs from 'query-string'
import { withStyles } from '@material-ui/styles'
import Backdrop from '@material-ui/core/Backdrop'
import Select from '../UI/inputs/FilterSelect'
import FiltersPopup from './modals/FiltersPopup'
import { FETCH_CONSULT_FILTERS } from '../../actions/listsAction'
import { FETCH_ORG_GO } from '../../actions/organisationsActions'
import { ReactComponent as Search } from '../../assets/images/icons/search_yellow.svg'
import { ReactComponent as Setting } from '../../assets/images/icons/setting_icon.svg'

const useStyles = () => ({
  backdrop: {
    zIndex: 1000
  }
})

let passFiltersToPopup = {
  consultation_form_id: '',
  event_id: '',
  subject_organisation_id: ''
}

class ConsultFilters extends Component {
  constructor(props) {
    super(props)
    this.state = {
      filters: {
        consultation_form_id: null,
        event_id: null,
        subject_organisation_id: null,
        title: null,
        id: null
      },
      isOpen: false
    }
  }

  componentDidMount() {
    this.props.dispatch(FETCH_CONSULT_FILTERS())
    this.props.dispatch(FETCH_ORG_GO())
    if (Object.keys(qs.parse(this.props.location.search)).length) {
      this.setState({
        filters: {
          ...this.state.filters,
          ...qs.parse(this.props.location.search)
        }
      })
    } else {
      this.props.fetchData()
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.filters !== prevState.filters) {
      this.props.history.push(`${this.props.match.path}?${qs.stringify(this.state.filters, { skipNull: true, skipEmptyString: true })}`)
      this.props.fetchData(this.state.filters)
    }
  }

  handleChange = (evt) => {
    if (+this.state.filters[evt.target.id] !== +evt.target.dataset.value) {
      this.setState({
        filters: {
          ...this.state.filters,
          [evt.target.id]: +evt.target.dataset.value
        }
      })
    } else {
      this.setState({
        filters: {
          ...this.state.filters,
          [evt.target.id]: null
        }
      })
    }
  }

  handleInput = (evt) => {
evt.preventDefault()

    if (evt.target.value) {
      this.setState({
        filters: {
          ...this.state.filters,
          [evt.target.id]: evt.target.value
        }
      })
    } else {
      this.setState({
        filters: {
          ...this.state.filters,
          [evt.target.id]: null
        }
      })
    }
  }

  checkVal = (evt) => {
    if (evt.keyCode < 48 || evt.keyCode > 57) {
      if (evt.keyCode === 8) {
        return
      }
      evt.preventDefault()
    }
  }

  toggleModalFilters = () => {
    this.setState({
      isOpen: !this.state.isOpen
    })
  }

  closeModalFilters = (evt) => {
    if (evt.target.id === 'backdrop') {
      this.toggleModalFilters()
    }
  }

  handleFilters = (data) => {
    this.setState({
      filters: {
        ...this.state.filters,
        consultation_form_id: data.consultation_form_id,
        event_id: data.event_id,
        subject_organisation_id: data.subject_organisation_id
      }
    })
    this.toggleModalFilters()
  }

  clearFilters = () => {
    this.setState({
      filters: {
        consultation_form_id: null,
        event_id: null,
        subject_organisation_id: null,
        title: null,
        id: null
      }
    })
    this.toggleModalFilters()
  }

  render() {
    const { orgGO } = this.props
    const { consultsForm, events } = this.props.lists

    return (
      <div>
        <Backdrop id="backdrop" className={this.props.classes.backdrop} open={this.state.isOpen} onClick={this.closeModalFilters}>
          <FiltersPopup filters={qs.parse(this.state.filters)} passFiltersToPopup={passFiltersToPopup} selectFilters={this.handleFilters} clearFilters={this.clearFilters} />
        </Backdrop>
        <div className={'table-filters'}>
          <div className={'table-filters__inner'}>
            <input value={this.state.filters.id ? this.state.filters.id : ''} className={'table-filters__field'} id="id" onKeyDown={this.checkVal} onChange={this.handleInput} type="text" placeholder={'Введіть ID...'} />
            <Search />
          </div>
          <div className={'table-filters__inner'}>
            <input value={this.state.filters.title ? this.state.filters.title : ''} className={'table-filters__field'} id="title" type="text" placeholder={'Назва'} onChange={this.handleInput} />
            <Search />
          </div>
          <Select selectedId={qs.parse(this.props.location.search).subject_organisation_id} handleChange={this.handleChange} id="subject_organisation_id" data={orgGO} name="ГО" />
          <Select selectedId={qs.parse(this.props.location.search).event_id} handleChange={this.handleChange} id="event_id" data={events} name="Захід" />
          <Select selectedId={qs.parse(this.props.location.search).consultation_form_id} handleChange={this.handleChange} id="consultation_form_id" data={consultsForm} name="Форма консультації" />
          <button className={'table-filters__btn'} onClick={this.toggleModalFilters}>
            Фільтри
          <Setting />
          </button>
        </div>
      </div>
    )
  }
}

function mapStateToProps({ organisations, lists }) {
  return {
    lists: lists.consultFilters,
    orgGO: organisations.orgGO
  }
}

ConsultFilters.propTypes = {
  fetchData: propTypes.func.isRequired
}

export default withRouter(connect(mapStateToProps)(withStyles(useStyles)(ConsultFilters)))
