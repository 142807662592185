import React, { useEffect, Fragment } from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import { connect } from "react-redux";
import "./App.scss";
import { useWindowSize } from "./helpers/resize";
import Navigation from "./components/UI/Navigation";
import Sidebar from "./components/UI/Sidebar";
import PlusMenu from "./components/UI/PlusMenu";
import Statistics from "./components/pages/Statistics";
import UserProfile from "./components/pages/UserProfile";
import OrgDetails from "./components/pages/Organisations/OrgDetails";
import UserOrg from "./components/pages/Organisations/UserOrg";
import Organisations from "./components/pages/Organisations/Organisations";
import UserOrgReq from "./components/pages/Organisations/UserOrgReq";
import UserConsults from "./components/pages/Consulations/UserConsults";
import IncomingConsults from "./components/pages/Consulations/IncomingConsults";
import Consults from "./components/pages/Consulations/Consults";
import Notifier from "./Notifiler";
import { getUser } from "./actions/authActions";

function App(props) {
  const [width] = useWindowSize();

  useEffect(() => {
    if (localStorage.getItem("access_token")) {
      props.dispatch(getUser());
    }
  }, []);

  return (
    <Router>
      <div className="wrapperGrid">
        <div className="Sidebar">{width > 767 && <Sidebar />}</div>
        <div className="Navigation">
          <Navigation />
        </div>
        <div className="App">
          <Switch>
            <Route exact path={"/"} component={Consults} />
            <Route path={"/confirm/:token"} component={Consults} />
            <Route path={"/statistics"} component={Statistics} />
            {localStorage.getItem("access_token") && (
              <Fragment>
                {props.user && (
                  <Fragment>
                    <Route path={"/profile"} component={UserProfile} />
                    <Route path={"/organisation/:id"} component={OrgDetails} />
                    <Route path={"/organisations"} component={Organisations} />
                    <Route path={"/my-organisations"} component={UserOrg} />
                    <Route
                      path={"/other-organisations"}
                      component={UserOrgReq}
                    />
                    <Route path={"/my-questions"} component={UserConsults} />
                    <Route
                      path={"/incoming-questions"}
                      component={IncomingConsults}
                    />
                  </Fragment>
                )}
              </Fragment>
            )}
            <Redirect to="/" />
          </Switch>
        </div>
      </div>

      {props.user && <PlusMenu />}
      <Fragment>
        <Notifier />
      </Fragment>
    </Router>
  );
}

function mapStateToProps({ auth }) {
  return {
    user: auth.user,
  };
}

export default connect(mapStateToProps)(App);
