import {
  GET_USER_ORG_REQ,
  REFETCH_USER_ORG_REQ,
  ERROR_USER_ORG_REQ
} from '../actions/types/organisations'

const initialState = {
  userOrganisationsReq: {},
  loading: false,
  error: false
}

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_USER_ORG_REQ:
      return {
        ...state,
        userOrganisationsReq: action.payload,
        loading: true,
        error: false
      }
    case REFETCH_USER_ORG_REQ:
      return {
        ...state,
        loading: false,
        error: false
      }
    case ERROR_USER_ORG_REQ:
      return {
        ...state,
        userOrganisationsReq: {},
        loading: true,
        error: true
      }
    default:
      return state
  }
}
