import React, { useState, useEffect } from 'react'
import * as propTypes from 'prop-types'
import { ReactComponent as Shevron } from '../../../assets/images/icons/shevron_bottom.svg'
import { ReactComponent as Search } from '../../../assets/images/icons/search_yellow.svg'

function Select(props) {
  const [ open, setOpen ] = useState(false)

  const [ filteredDatas, setFilteredDatas ] = useState([])

  const [ activeIndex, setActiveIndex ] = useState(null)

  useEffect(() => {
    setFilteredDatas(props.data)
    if (props.selectedId && props.data) {
      let index = props.data.findIndex(item => item.id == props.selectedId)
      setActiveIndex(index)
    } else {
      setActiveIndex(null)
    }
  }, [props.data, props.selectedId])

  const handleOpen = (evt) => {
    if (!evt.target.closest('.filter-select__inner')) {
      setOpen(true)
    }
  }

  const handleClose = () => setOpen(false) 

  const filterData = (evt) => {
    let tempArr = props.data.filter((item) => {
      return item.name.toLowerCase().includes(evt.target.value.toLowerCase());
    })
    setFilteredDatas(tempArr)
  }

  const selectItem = (evt, index) => {
    if (index !== activeIndex) {
      setActiveIndex(index)
    } else {
      setActiveIndex(null)
    }
    setFilteredDatas(props.data)
    props.handleChange(evt)
  }

  return (
    <div className={'table-filters__inner'} onMouseEnter={handleOpen} onMouseLeave={handleClose}>
      <p>{props.name}</p>
      <Shevron />
      {open && (
        <div className="filter-select">
          <div className="filter-select__inner">
            <input
              onInput={filterData}
              className="filter-select__field"
              type="text"
              placeholder="Шукати..."
            />
            <Search />
          </div>
          {filteredDatas && filteredDatas.length ? (
            filteredDatas.map((item, index) => {
              return (
                <div
                  onClick={(evt) => selectItem(evt, index)}
                  id={props.id}
                  data-value={item.id}
                  key={item.id}
                  className={`filter-select__item ${index === activeIndex ? 'filter-select__item--active' : ''}`}
                >
                  {item.name && item.name.length > 15
                    ? `${item.name.slice(0, 15)}...`
                    : item.name}
                  {item.title && item.title.length > 15
                    ? `${item.title.slice(0, 15)}...`
                    : item.title}  
                </div>
              )
            })
          ) : (
            <div className="filter-select__item">Нічого не знайдено</div>
          )}
        </div>
      )}
    </div>
  )
}

Select.propTypes = {
  name: propTypes.string.isRequired,
  data: propTypes.arrayOf(propTypes.object),
  handleChange: propTypes.func.isRequired,
  selectedId: propTypes.string
};

export default Select
