import React, { useEffect, useState, Fragment } from 'react'
import * as propTypes from 'prop-types'
import Checkbox from '@material-ui/core/Checkbox'
import { ReactComponent as Shevron } from '../../../assets/images/icons/shevron_bottom.svg'

function MultiSelect (props) {
  const [ open, setOpen ] = useState(false)

  const [ selectedItem, setSelectedItem ] = useState([])

  const { placeholder, name, register, handleChange, errors, isSubmitted, data, type, field, disabled } = props

  const getSelectItem = () => {
    let tempArr = data.filter(item => field.includes(item.name || item.title))
    if (tempArr[0]) {
      setSelectedItem([ ...tempArr ])
    }
  }

  useEffect(() => {
    if (type && field) {
      getSelectItem()
    }
  }, [data])

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const selectItem = (data, e) => {
    e.stopPropagation()
    
    if (!selectedItem.some(item => item.id === data.id)) {
      data.checked = true
      setSelectedItem(
        [...selectedItem, data]
      )
    } else {
      data.checked = false
      selectedItem.splice(selectedItem.indexOf(data), 1)
      setSelectedItem(
        [...selectedItem]
      )
    }
  }

  return (
    <div className="custom-select">
      <div            
        className={
          `custom-select__field ${errors[name] ? 'input-error' : ''} ${isSubmitted && !errors[name] ? 'input-valid' : ''} ${disabled && 'input-disabled'}`
        }
        onMouseEnter={handleOpen}
        onMouseLeave={handleClose}
      >
        <input
          type="text"
          placeholder={placeholder}
          ref={register}
          name={name}
          value={selectedItem.map(item => item.id).join(',')}
          disabled
          onChange={handleChange}
        />
        {!selectedItem.length ?
          <p>{placeholder}</p>
          :
          <p>
            {
              selectedItem.map(item => item.name || item.title).join(', ')
            }
          </p>
        }
        <Shevron />
        {open && !disabled &&
          <div className="custom-select__inner" onMouseLeave={handleClose}>
            {data.length ?
              data.map((item) => {
                return (
                  <div key={item.id} style={{'display': 'flex', 'alignItems': 'center', 'justifyContent': 'space-between'}} onClick={selectItem.bind(this, item)}>
                    <div className="custom-select__item">
                      {item.name || item.title}
                    </div>
                    <Checkbox name={item.name || item.title} checked={item.checked} />
                  </div>
                )
              })
              : <div className="custom-select__item">No-data</div>
            }
          </div>
        }
      </div>
      {errors[name] && errors[name].type === 'required' &&
        <span className="input-error-text">Будь ласка, заповніть поле.</span>
      }
    </div>
  )
}

MultiSelect.propTypes = {
  placeholder: propTypes.string,
  name: propTypes.string.isRequired,
  register: propTypes.func.isRequired,
  handleChange: propTypes.func.isRequired,
  errors: propTypes.object.isRequired,
  isSubmitted: propTypes.bool.isRequired,
  data: propTypes.arrayOf(propTypes.object),
  type: propTypes.string,
  field: propTypes.array,
  disabled: propTypes.bool
}

export default MultiSelect