import React, { useState, useEffect, Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import { useWindowSize } from "../../../helpers/resize";
import propTypes from "prop-types";
import Table from "@material-ui/core/Table";
import Dialog from "@material-ui/core/Dialog";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import CandidatesInOrg from "../modals/CandidatesInOrg";
import { ReactComponent as Site } from "../../../assets/images/icons/site.svg";
import { ReactComponent as Fb } from "../../../assets/images/icons/fb.svg";
import { ReactComponent as Inst } from "../../../assets/images/icons/inst.svg";
import { ReactComponent as Twitter } from "../../../assets/images/icons/tw.svg";
import { ReactComponent as Edit } from "../../../assets/images/icons/edit.svg";
import { ReactComponent as Question } from "../../../assets/images/icons/question.svg";

const useStyles = makeStyles({
  table: {
    backgroundColor: "transparent",
  },
  head: {
    color: "#3FB2C6",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "16px",
  },
  row: {
    fontSize: "14px",
    lineHeight: "20px",
    color: "#000000",
  },
});

const headers = [
  /* 'ID', */
  "Назва організації",
  "Тип",
  /* 'ЄДРПОУ',*/
  "Напрямок діяльності",
  /* 'Район', */
  "Контакти",
  "Статус модерації",
  "Запити на вступ",
];

function DataTable(props) {
  const classes = useStyles();

  const [isOpen, setIsOpen] = useState({});
  const [width] = useWindowSize();

  const { data } = props;

  useEffect(() => {
    data.forEach((item, index) => {
      isOpen[index] = false;
      setIsOpen({ ...isOpen });
    });
  }, []);

  const openDialog = (index) => {
    isOpen[index] = true;
    setIsOpen({ ...isOpen });
  };

  const closeDialog = (index) => {
    isOpen[index] = false;
    setIsOpen({ ...isOpen });
  };

  if (data.length) {
    const mobData = data.map((el) => ({ ...el, ...headers }));

    return (
      <Fragment>
        {Object.keys(data).length && (
          <TableContainer>
            <Table className={classes.table}>
              {width > 1200 ? (
                <>
                  <TableHead>
                    <TableRow>
                      {headers.map((head, index) => {
                        return (
                          <TableCell
                            className={classes.head}
                            key={index}
                            align="left"
                          >
                            {head}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data.map((item, index) => {
                      return (
                        <Fragment key={Math.random()}>
                          <TableRow key={item.id}>
                            {/* <TableCell className={classes.row}>
                          <div className="table__cell">
                            <span className="table__cell-link">{item.id}</span>
                            <Question style={{ cursor: 'pointer' }} />
                          </div>
                        </TableCell> */}
                            <TableCell className={classes.row}>
                              <Link
                                className="table__title"
                                to={`/organisation/${item.id}`}
                                style={{ textDecoration: "none" }}
                              >
                                {item.title}
                              </Link>
                            </TableCell>
                            <TableCell className={classes.row}>
                              {item.type}
                            </TableCell>
                            {/* <TableCell className={classes.row}>
                          {item.edrpou}
                        </TableCell> */}
                            <TableCell className={classes.row}>
                              {item.activity}
                            </TableCell>
                            {/* <TableCell className={classes.row}>
                          {item.area}
                        </TableCell> */}
                            <TableCell className={classes.row}>
                              <div className="table__mail">
                                <a href={`mailto:${item.email}`}>
                                  <Site />
                                </a>
                                <a
                                  className="table__mail-link"
                                  href={`mailto:${item.email}`}
                                >
                                  {`${item.email.slice(0, 10)}...`}
                                </a>
                              </div>
                              <div className="table__cell">
                                <a
                                  className="table__cell-link"
                                  href={item.social_links.fb}
                                >
                                  <Fb />
                                </a>
                                <a
                                  className="table__cell-link"
                                  href={item.social_links.instagram}
                                >
                                  <Inst />
                                </a>
                                <a href={item.social_links.telegram}>
                                  <Twitter />
                                </a>
                              </div>
                            </TableCell>
                            <TableCell align="center" className={classes.row}>
                              {item.textStatus}
                            </TableCell>
                            <TableCell align="center" className={classes.row}>
                              <div className="table__candidates">
                                <div
                                  className="table__candidates-count"
                                  onClick={() => openDialog(index)}
                                >
                                  {item.candidates.length}
                                </div>
                                <Edit
                                  onClick={() => props.action(item.id)}
                                  style={{ cursor: "pointer" }}
                                />
                              </div>
                            </TableCell>
                          </TableRow>
                          <Dialog
                            key={index}
                            fullScreen
                            open={!isOpen}
                            onClose={() => closeDialog(index)}
                          >
                            <CandidatesInOrg
                              data={item.candidates}
                              orgId={item.id}
                              onClose={() => closeDialog(index)}
                            />
                          </Dialog>
                        </Fragment>
                      );
                    })}
                  </TableBody>
                </>
              ) : (
                <TableBody>
                  {mobData.map((el, i) => (
                    <Fragment key={el.id}>
                      <TableRow style={{ background: "#f1f1f1" }}>
                        <TableCell className={`${classes.row} table__title`}>
                          {el[0]}
                        </TableCell>
                        <TableCell className={`${classes.row} table__title`}>
                          <Link
                            className="table__title"
                            to={`/organisation/${el.id}`}
                            style={{ textDecoration: "none" }}
                          >
                            {el.title}
                          </Link>
                        </TableCell>
                        <TableCell>
                          <Edit
                            onClick={() => props.action(el.id)}
                            style={{ cursor: "pointer" }}
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className={classes.head}>{el[1]}</TableCell>
                        <TableCell className={`${classes.row} table__title`}>
                          {el.type}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className={classes.head}>{el[2]}</TableCell>
                        <TableCell className={classes.row}>
                          {el.activity}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className={classes.head}>{el[3]}</TableCell>
                        <TableCell className={classes.row}>
                          <div className="table__mail">
                            <a href={`mailto:${el.email}`}>
                              <Site />
                            </a>
                            <a
                              className="table__mail-link"
                              href={`mailto:${el.email}`}
                            >
                              {`${el.email.slice(0, 10)}...`}
                            </a>
                          </div>
                          <div className="table__cell">
                            <a
                              className="table__cell-link"
                              href={el.social_links.fb}
                            >
                              <Fb />
                            </a>
                            <a
                              className="table__cell-link"
                              href={el.social_links.instagram}
                            >
                              <Inst />
                            </a>
                            <a href={el.social_links.telegram}>
                              <Twitter />
                            </a>
                          </div>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className={classes.head}>{el[4]}</TableCell>
                        <TableCell className={classes.row}>
                          {el.textStatus}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className={classes.head}>{el[5]}</TableCell>
                        <TableCell align="center" className={classes.row}>
                          <div className="table__candidates">
                            <div
                              className="table__candidates-count"
                              onClick={() => openDialog(i)}
                            >
                              {el.candidates.length}
                            </div>
                          </div>
                        </TableCell>
                      </TableRow>
                    </Fragment>
                  ))}
                </TableBody>
              )}
            </Table>
          </TableContainer>
        )}
      </Fragment>
    );
  } else {
    return (
      <div className={"table"}>
        <p>Дані відсутні...</p>
      </div>
    );
  }
}

DataTable.propTypes = {
  data: propTypes.arrayOf(propTypes.object).isRequired,
  action: propTypes.func,
};

export default DataTable;
