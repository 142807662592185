import React, { Component } from 'react'
import CircularProgress from "@material-ui/core/CircularProgress";
import { ReactComponent as NextWhite } from '../../../assets/images/icons/btn_next_white.svg';
import Grid from "@material-ui/core/Grid";
import * as propTypes from "prop-types";
import { connect } from "react-redux";
import axios from 'axios';
import { config } from "../../../env/enviroment";
import { ReactComponent as Shevron } from "../../../assets/images/icons/shevron_bottom.svg";
import { ReactComponent as Calendar } from "../../../assets/images/icons/calendar_icon.svg";
import { DatePicker } from "@material-ui/pickers";
import { updateUser } from "../../../actions/authActions";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControl from "@material-ui/core/FormControl";
import Radio from "@material-ui/core/Radio";
import CheckBox from "@material-ui/core/Checkbox";
import './UpdateForm.scss';

class UpdateForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			fields: {
				info: {
					gender: undefined,
				},
				auth: {},
				address: {
					city_id: undefined,
					area_id: undefined,
				},
				socialLinks: {},
				agree: false,
			},
			cities: [],
			areas: [],
			selectedCity: null,
			selectedArea: null,
			isCitiesOpen: false,
			isAreasOpen: false,
			genders: [],
			dateChanged: false,
			date: new Date(315530000000),
			errors: null,
			invalid: true
		}

		
	}

	componentDidMount() {
		axios
			.get(config.baseURL + 'list/cities')
			.then(res => this.setState({ cities: res.data.result }));
		axios
			.get(config.baseURL + 'list/genders')
			.then(res => this.setState({ genders: res.data.result }));
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		const { selectedCity, genders } = this.state;
		if (selectedCity !== prevState.selectedCity && selectedCity) {
			axios
				.get(config.baseURL + `list/city-areas?cityId=${selectedCity.id}`)
				.then(res => this.setState({ areas: res.data.result }));
		}
	}

	selectCity = (city) => {
		const { fields } = this.state;
		this.setState({
			selectedCity: city,
			fields: { ...fields, address: { ...fields.address, city_id: city.id } }
		}, this.validate);
	};

	selectArea = (area) => {
		const { fields } = this.state;
		this.setState({
			selectedArea: area,
			fields: { ...fields, address: { ...fields.address, area_id: area.id } }
		}, this.validate);
	};

	genderChange = (e) => {
		const { fields } = this.state;
		this.setState({
			fields: { ...fields, info: { ...fields.info, gender: e.target.value * 1 } }
		}, this.validate);
	};


	



	changeAgree = (e) => {
		const { fields } = this.state;
		this.setState({
			fields: { ...fields, info: { ...fields.info, agree: e.currentTarget.checked } }
		}, this.validate)
		
	}







	changeSocials = (e, field) => {
		const { fields } = this.state;
		this.setState({
			fields: { ...fields, socialLinks: { ...fields.socialLinks, [field]: e.target.value } }
		}, this.validate);
	};

	validate = () => {
		const { fields, dateChanged } = this.state;
		const errors = {};
		const allFields = { ...fields.info, ...fields.address, ...fields.socialLinks };
		const requiredFields = [
			'city_id',
			'area_id',
			'gender',
			'agree',
		];

		requiredFields.forEach(field => {
			if (!allFields[field]) {
				errors[field] = 'Будь ласка, заповніть поле.'
			}
		});

		if (
			fields.socialLinks.telegram && (
				fields.socialLinks.telegram[0] !== 't' &&
				fields.socialLinks.telegram[0] !== '@'
			)
		) {
			errors['telegram'] = 'Невірно вказаний telegram'
		}

		if (!dateChanged) {
			errors['date'] = 'Будь ласка, заповніть поле.'
		}

		this.setState({
			errors: errors,
			invalid: Object.values(errors).length !== 0
		})
	};

	onSubmit = (e) => {
		e.preventDefault();
		this.props.dispatch(updateUser(this.state.fields));
	};

	render() {
		const { loading, dispatch } = this.props;
		const { fields, cities, areas, selectedCity, selectedArea, isCitiesOpen, isAreaOpen, errors, invalid, date, dateChanged, genders } = this.state;
		
		return (
			<form>
				<Grid container spacing={0}>
					<Grid item sm={6}>
						<div className={`input select`}
							onClick={() => cities.length > 0 && this.setState({ isCitiesOpen: !isCitiesOpen })}>
							<input type="text" placeholder={'Місто'} defaultValue={selectedCity ? selectedCity.name : ''}
								disabled={cities.length === 0}
								className={`${fields.address.city_id && 'filled'}`} autoComplete="off" />
							<ul className={isCitiesOpen ? 'list open' : 'list'}
								onClick={() => this.setState({ isCitiesOpen: !isCitiesOpen })}>
								{
									cities && cities.map((city) => {
										return (
											<li key={city.id} onClick={() => this.selectCity(city)}>{city.name}</li>
										)
									})
								}
							</ul>
							<div className={'decoration'}>
								<Shevron />
							</div>
						</div>
					</Grid>
					<Grid item sm={6}>
						<div className={`input select`}
							onClick={() => areas.length > 0 && this.setState({ isAreaOpen: !isAreaOpen })}>
							<input type="text" placeholder={'Район'} defaultValue={selectedArea ? selectedArea.name : ''}
								disabled={areas.length === 0}
								className={`${fields.address.area_id && 'filled'}`} autoComplete="off" />
							<ul className={isAreaOpen ? 'list open' : 'list'}
								onClick={() => this.setState({ isAreaOpen: !isAreaOpen })}>
								{
									areas && areas.map((area) => {
										return (
											<li key={area.id} onClick={() => this.selectArea(area)}>{area.name}</li>
										)
									})
								}
							</ul>
							<div className={'decoration'}>
								<Shevron />
							</div>
						</div>
					</Grid>
					<Grid item sm={6}>
						<div className={'datePicker'}>
							<div className={'input'} onClick={() => document.getElementById('datePicker').click()}>
								<input type="text" placeholder={'Дата народження'} />
							</div>
							<DatePicker
								disableFuture
								openTo="year"
								format="dd MMMM yyyy"
								label="Дата народження"
								views={["year", "month", "date"]}
								name={'date'}
								value={date}
								id={'datePicker'}
								className={'filled'}
								style={{ zIndex: dateChanged ? 0 : -1 }}
								onChange={(date) => this.setState({ date, dateChanged: true }, this.validate)}
							/>
							<div className={'decoration'}>
								<Calendar />
							</div>
						</div>
					</Grid>
					<Grid item sm={6}>
						<div className={'radio'}>
							<FormControl>
								<RadioGroup aria-label="gender" value={fields.info.gender} name="gender" onChange={this.genderChange}>
									{
										genders.map(gender => {
											return (
												<FormControlLabel key={gender.value} value={gender.value.toString()} control={<Radio />} label={gender.title} />
											);
										})
									}
								</RadioGroup>
							</FormControl>
						</div>
					</Grid>
					<Grid item sm={6}>
						<div className={'input'}>
							<input type="text" placeholder={'Посилання на ваш Facebook'} onChange={(e) => this.changeSocials(e, 'fb')} />
						</div>
					</Grid>
					<Grid item sm={6}>
						<div className={'input'}>
							<input type="text" placeholder={'Посилання на ваш Twitter'} onChange={(e) => this.changeSocials(e, 'twitter')} />
						</div>
					</Grid>
					<Grid item sm={3} ></Grid>
					<Grid item sm={6}>
						<div className={'input'}>
							<input name={'telegram'} type="text" placeholder={'Посилання на ваш Telegram'} onChange={(e) => this.changeSocials(e, 'telegram')} />
							<p className={'error'}>{!!errors && !!errors.telegram ? errors.telegram : false}</p>
						</div>
					</Grid>
					<Grid item sm={12} >
						
						<FormControlLabel 
						 control={<CheckBox 
							classes={{
								root: `CustomCheckbox`
							}}
							name={'agree'} 
							type="checkbox" 
							onChange={(e) => this.changeAgree(e, 'agree')}
						 />}
						 label="згода на обробку персональних даних"
						 color="white"
						 classes={{
							 label: 'ConfirmLabel'
						 }}
						/>
							
					</Grid>
				</Grid>
				{
					loading ? <CircularProgress style={{ color: '#FEDD00', height: '41px', width: '41px' }} />
						: <button disabled={invalid} onClick={this.onSubmit}><NextWhite /></button>
				}
			</form>
		)
	}
}


UpdateForm.propTypes = {
	dispatch: propTypes.func.isRequired,
	loading: propTypes.bool
};

function mapStateToProps({ auth }) {
	return {
		loading: auth.loading
	}
}

export default connect(mapStateToProps)(UpdateForm);
