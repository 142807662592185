import React from "react";
import * as propTypes from "prop-types";
import { connect } from "react-redux";
import IconButton from "@material-ui/core/IconButton";
import ConsultationRequestForm from "../forms/ConsultationRequestForm";
import { FETCH_USER_CONSULT } from "../../../actions/cunsulationActions";
import { ReactComponent as CloseIcon } from "../../../assets/images/icons/close.svg";

function ConsultationRequest(props) {
  const onSubmit = (data) => {
    props.dispatch(props.action(data)).then(() => {
      props.dispatch(
        FETCH_USER_CONSULT({ subject_user_id: props.subjectUserId })
      );
      props.dispatch(
        FETCH_USER_CONSULT({
          responsible_organisation_id: props.orgMember,
          responsible_user_id: `${props.subjectUserId}, null`,
        })
      );
      props.onClose();
    });
  };

  return (
    <div className="consultation">
      <div className="consultation__close-icon">
        <IconButton
          edge="start"
          color="inherit"
          onClick={() => props.onClose(true)}
          aria-label="close"
        >
          <CloseIcon style={{ width: "26px", height: "26px" }} />
        </IconButton>
      </div>
      <h2 className="consultation__title">Запропонувати консультацію</h2>
      <ConsultationRequestForm
        onSubmit={(data) => onSubmit(data)}
        type={props.type && props.type}
      />
    </div>
  );
}

ConsultationRequest.propTypes = {
  action: propTypes.func,
  onClose: propTypes.func,
  type: propTypes.string,
  orgMember: propTypes.string,
};

function mapStateToProps({ auth }) {
  return {
    subjectUserId: auth.user.id,
  };
}

export default connect(mapStateToProps)(ConsultationRequest);
