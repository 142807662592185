import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import Input from "../../UI/inputs/CustomInput";
import InputSecret from "../../UI/inputs/CustomInputSecret";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import { FETCH_FILTERS } from "../../../actions/listsAction";
import { UPDATE_USER_PROFILE } from "../../../actions/profileActions";
import { getUser } from "../../../actions/authActions";
import { ReactComponent as Send } from "../../../assets/images/icons/icon_send-white.svg";

const CustomRadio = withStyles({
  root: {
    color: "#FEDD00",
    "&$checked": {
      color: "#FEDD00",
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

function ProfileForm(props) {
  const {
    gender,
    name,
    surname,
    patronymic,
    email,
    phone,
    ...social_links
  } = props.user;

  const {
    register,
    handleSubmit,
    setValue,
    errors,
    formState,
    watch,
  } = useForm({
    defaultValues: {
      name: name,
      surname: surname,
      patronymic: patronymic,
      email: email,
      phone: phone,
      fb: social_links.fb,
      instagram: social_links.instagram,
      telegram: social_links.telegram,
    },
  });

  const { isSubmitted } = formState;

  const handleChange = (evt) => {
    setValue(evt.target.name, evt.target.value);
  };

  const [params, setParams] = useState({
    gender: "",
  });

  const selectParam = (evt) => {
    params[evt.target.name] = evt.target.value;
    setParams({ ...params });
  };

  useEffect(() => {
    props.dispatch(FETCH_FILTERS());
    params.gender = gender && gender.toString();
    setParams({ ...params });
    register({ name: "surname" }, { required: true });
    register({ name: "name" }, { required: true });
    register({ name: "patronymic" }, { required: true });
    register(
      { name: "email" },
      {
        required: true,
        pattern: {
          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
          message: "Невірно вказаний email",
        },
      }
    );
    register({ name: "password" }, { minLength: 6, required: true });
    register(
      { name: "password2" },
      {
        minLength: 6,
        validate: (value) => value === watch("password"),
        required: true,
      }
    );
    register(
      { name: "phone" },
      {
        pattern: {
          value: /^\+?3?8?(0\d{9})$/,
          message: "Невірно вказаний телефон",
        },
      }
    );
    register({ name: "fb" });
    register({ name: "instagram" });
    register({ name: "telegram" });
  }, []);

  const submitForm = (data) => {
    let updateUser = {
      info: {
        surname: data.surname,
        name: data.name,
        patronymic: data.patronymic,
        gender: params.gender,
      },
      auth: {
        email: data.email,
        phone: data.phone,
        password: data.password2 ? data.password2 : null,
      },
      socialLinks: {
        fb: data.fb,
        instagram: data.instagram,
        telegram: data.telegram,
      },
    };
    props.dispatch(UPDATE_USER_PROFILE(updateUser)).then(() => {
      props.dispatch(getUser());
    });
  };

  return (
    <form className="profile__form" onSubmit={handleSubmit(submitForm)}>
      <div>
        <h2 className="profile__caption">Особисті дані</h2>
        <div className="row profile__row">
          <div className="col col-xs-12 col-sm-6">
            <Input
              placeholder="Ваше прізвище*"
              name="surname"
              handleChange={handleChange}
              register={register}
              errors={errors}
              isSubmitted={isSubmitted}
            />
          </div>
          <div className="col col-xs-12 col-sm-6">
            <Input
              placeholder="Ім’я*"
              name="name"
              handleChange={handleChange}
              register={register}
              errors={errors}
              isSubmitted={isSubmitted}
            />
          </div>
          <div className="col col-xs-12 col-sm-6">
            <Input
              placeholder="По-батькові*"
              name="patronymic"
              handleChange={handleChange}
              register={register}
              errors={errors}
              isSubmitted={isSubmitted}
            />
          </div>
          <div className="col col-xs-12 col-sm-6">
            <Input
              placeholder="Ваш email*"
              name="email"
              handleChange={handleChange}
              register={register}
              errors={errors}
              isSubmitted={isSubmitted}
            />
          </div>
          <div className="col col-xs-12 col-sm-6">
            <InputSecret
              placeholder="Пароль*"
              name="password"
              handleChange={handleChange}
              register={register}
              errors={errors}
              isSubmitted={isSubmitted}
            />
          </div>
          <div className="col col-xs-12 col-sm-6">
            <InputSecret
              placeholder="Повторіть пароль*"
              name="password2"
              handleChange={handleChange}
              register={register}
              errors={errors}
              isSubmitted={isSubmitted}
            />
          </div>
          <div className="col col-xs-12 col-sm-6">
            <RadioGroup
              name="gender"
              value={params.gender}
              onChange={selectParam}
            >
              <div className="row">
                {props.genders &&
                  props.genders.map((item) => {
                    return (
                      <div key={item.value} className="col col-sm-3">
                        <FormControlLabel
                          value={item.value.toString()}
                          control={<CustomRadio />}
                          label={<label>{item.title}</label>}
                        />
                      </div>
                    );
                  })}
              </div>
            </RadioGroup>
          </div>
        </div>
        <h2 className="profile__caption">Зв'язок</h2>
        <div className="row row2">
          <div className="col col-xs-12 col-sm-6">
            <Input
              placeholder="Номер телефону*"
              name="phone"
              handleChange={handleChange}
              register={register}
              errors={errors}
              isSubmitted={isSubmitted}
            />
          </div>
          <div className="col col-xs-12 col-sm-6">
            <Input
              placeholder="Посилання на ваш Facebook"
              name="fb"
              handleChange={handleChange}
              register={register}
              errors={errors}
              isSubmitted={isSubmitted}
            />
          </div>
          <div className="col col-xs-12 col-sm-6">
            <Input
              placeholder="Посилання на ваш Instagram"
              name="instagram"
              handleChange={handleChange}
              register={register}
              errors={errors}
              isSubmitted={isSubmitted}
            />
          </div>
          <div className="col col-xs-12 col-sm-6">
            <Input
              placeholder="Посилання на ваш Telegram"
              name="telegram"
              handleChange={handleChange}
              register={register}
              errors={errors}
              isSubmitted={isSubmitted}
            />
          </div>
        </div>
      </div>
      <button className={"button-send profile-button"}>
        <span>Зберегти</span>
        <Send />
      </button>
    </form>
  );
}

function mapStateToProps({ auth, lists }) {
  return {
    user: auth.user,
    genders: lists.filters.genders,
  };
}

export default connect(mapStateToProps)(ProfileForm);
