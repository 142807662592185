import axios from "../config/axiosConfig";
import { GET_FILTERS, GET_CONSULT_FILTERS } from "./types/lists";
import { enqueueSnackbar as enqueueSnackbarAction } from "./snackBarActions";

export const FETCH_FILTERS = () => (dispatch) => {
  // organisations filters
  const FETCH_ACTIVITY = axios.get("list/activity-fields");
  const FETCH_ORG_TYPES = axios.get("list/organisation-types");
  const FETCH_CITY_AREAS = axios.get("list/city-areas");
  const FETCH_GENDERS = axios.get("list/genders");
  const enqueueSnackbar = (...args) => dispatch(enqueueSnackbarAction(...args));
  Promise.all([
    FETCH_ACTIVITY,
    FETCH_ORG_TYPES,
    FETCH_CITY_AREAS,
    FETCH_GENDERS,
  ])
    .then((value) => {
      dispatch({
        type: GET_FILTERS,
        payload: {
          activity: value[0].data.result,
          orgTypes: value[1].data.result,
          cityAreas: value[2].data.result,
          genders: value[3].data.result,
        },
      });
    })
    .catch((err) => {
      enqueueSnackbar({
        message: err.response.data.result.message,
        options: {
          variant: "warning",
        },
      });
    });
};

export const FETCH_CONSULT_FILTERS = () => (dispatch) => {
  // Consults filters
  const FETCH_CONSULT_FORM = axios.get("list/consultation-form?type=10");
  const FETCH_CONSULT_EVENT = axios.get("list/events?consultationFormId=1");
  const FETCH_THEMATICS = axios.get("/list/subjects");
  const enqueueSnackbar = (...args) => dispatch(enqueueSnackbarAction(...args));
  Promise.all([FETCH_CONSULT_FORM, FETCH_CONSULT_EVENT, FETCH_THEMATICS])
    .then((value) => {
      dispatch({
        type: GET_CONSULT_FILTERS,
        payload: {
          consultsForm: value[0].data.result,
          events: value[1].data.result,
          thematics: value[2].data.result,
        },
      });
    })
    .catch((err) => {
      enqueueSnackbar({
        message: err.response.data.result.message,
        options: {
          variant: "warning",
        },
      });
    });
};
