import {
  GET_USER_ORG,
  REFETCH_USER_ORG,
  ERROR_USER_ORG
} from '../actions/types/organisations'

const initialState = {
  userOrganisations: {},
  loading: false,
  error: false
}

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_USER_ORG:
      return {
        ...state,
        userOrganisations: action.payload,
        loading: true,
        error: false
      }
    case REFETCH_USER_ORG:
      return {
        ...state,
        loading: false,
        error: false
      }
    case ERROR_USER_ORG:
      return {
        ...state,
        userOrganisations: {},
        loading: true,
        error: true
      }
    default:
      return state
  }
}
