import {
  CLOSE_AUTH_PAGE,
  CREATE_ACC_STARTED,
  CREATE_ACC_SUCCESS,
  LOGIN_REQUEST_STARTED,
  LOGIN_REQUEST_SUCCESS,
  LOGOUT,
  REQUEST_FAILURE,
  SET_AUTH_PAGE,
} from "./types/auth";
import { config } from "../env/enviroment";
import axios from "axios";

import axiosAuth from "../config/axiosAuthConfig";

// Pages
export const setAuthPage = (page) => ({
  type: SET_AUTH_PAGE,
  payload: {
    page,
  },
});

export const closeAuth = () => ({
  type: CLOSE_AUTH_PAGE,
});

// login

const loginRequestStarted = () => ({
  type: LOGIN_REQUEST_STARTED,
});

const loginRequestSuccess = (user) => ({
  type: LOGIN_REQUEST_SUCCESS,
  payload: {
    user,
  },
});

const requestFailure = (error) => ({
  type: REQUEST_FAILURE,
  payload: {
    error,
  },
});

export function logIn(values) {
  return (dispatch) => {
    dispatch(loginRequestStarted());
    axios
      .post(config.baseURL + "login", values)
      .then((res) => {
        localStorage.setItem(
          "access_token",
          JSON.stringify(res.data.result.access_token)
        );
        dispatch(loginRequestSuccess(res.data.result));
        dispatch(getUser());
        dispatch(closeAuth());
      })
      .catch((err) => {
        dispatch(requestFailure(err.response.data.result));
      });
  };
}

const logOutUser = () => ({
  type: LOGOUT,
});

export function logOut() {
  return (dispatch) => {
    localStorage.removeItem("access_token");
    dispatch(logOutUser());
  };
}

// CREATE ACC

const createAccStarted = () => ({
  type: CREATE_ACC_STARTED,
});

const createAccSuccess = (user) => ({
  type: CREATE_ACC_SUCCESS,
  payload: {
    user,
  },
});

export function createAcc(values) {
  return (dispatch) => {
    dispatch(createAccStarted());
    axios
      .post(config.baseURL + "signup", { ...values })
      .then((res) => {
        if (res.status === 200) {
          dispatch(setAuthPage("checkEmail"));
          dispatch(
            createAccSuccess(res.data.result, res.data.result.access_token)
          );
        } else {
          dispatch(requestFailure(res.data.result));
        }
      })
      .catch((err) => {
        dispatch(requestFailure(err.response.data.result));
      });
  };
}

export function approveAcc(token) {
  // TODO: разкоментировать

  return (dispatch) => {
    dispatch(setAuthPage("approve"));
    // dispatch(setAuthPage('update'));  // TODO: Удалить
    axios
      .get(config.baseURL + `confirm?token=${token}`)
      .then((res) => {
        localStorage.setItem(
          "access_token",
          JSON.stringify(res.data.result.token)
        );
        if (res.data.status) {
          setTimeout(() => dispatch(setAuthPage("update")), 2000);
        } else {
          dispatch(closeAuth());
        }
      })
      .catch((err) => {
        dispatch(requestFailure(err.response.data.result));
        dispatch(closeAuth());
      });
  };
}

export function getUser() {
  return (dispatch) => {
    return axios
      .get(config.baseURL + "profile", {
        headers: {
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("access_token")),
        },
      })
      .then((res) => {
        axiosAuth.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${JSON.parse(localStorage.getItem("access_token"))}`;
        dispatch(
          loginRequestSuccess(
            res.data.result,
            JSON.parse(localStorage.getItem("access_token"))
          )
        );
      })
      .catch((err) => {
        // dispatch(requestFailure(err.response.data.result));
      });
  };
}

export function updateUser(data) {
  return (dispatch) => {
    dispatch(loginRequestStarted());
    axios
      .put(
        config.baseURL + "profile/edit",
        { ...data },
        {
          headers: {
            Authorization: `Bearer ${JSON.parse(
              localStorage.getItem("access_token")
            )}`,
          },
        }
      )
      .then((res) => {
        dispatch(loginRequestSuccess(res.data.result));
        dispatch(closeAuth());
      })
      .catch((err) => {
        // dispatch(requestFailure(err.response.data.result));
      });
  };
}
