import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import propTypes from 'prop-types'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableSelect from '../../UI/inputs/TableSelect'
import { ACCEPT_CANDIDATE, FETCH_USER_ORG, REFUSE_CANDIDATE } from '../../../actions/organisationsActions'
import { ReactComponent as Fb } from '../../../assets/images/icons/fb-color.svg'
import { ReactComponent as Inst } from '../../../assets/images/icons/inst-color.svg'

const useStyles = makeStyles({
  container: {
    overflowX: 'visible'
  },
  table: {
    backgroundColor: 'transparent'
  },
  head: {
    color: '#3FB2C6',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '16px'
  },
  row: {
    fontSize: '14px',
    lineHeight: '20px',
    color: '#ffffff'
  }
})

const headers = ['ПІБ', 'Телефон', ' E-mail', 'Соціальні мережі', 'Що будемо з ним робити']

const actionData = [
  { name: 'Прийняти', value: 'accept' },
  { name: 'Відхилити', value: 'reject' }
]

function DataTable (props) {
  const classes = useStyles()

  const handleChange = (evt, val) => {
    if (val === actionData[0].value) {
      props.dispatch(ACCEPT_CANDIDATE(props.orgId, {
        members: [evt.target.id]
      }))
        .then(() => {
          props.dispatch(FETCH_USER_ORG())
        })
    } else if (val === actionData[1].value) {
      props.dispatch(REFUSE_CANDIDATE(props.orgId, {
        candidate: evt.target.id
      }))
        .then(() => {
          props.dispatch(FETCH_USER_ORG())
        })
    }
  }

  if (props.data.length) {
    return (
      <div className="container">
        <TableContainer className={classes.container}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                {
                  headers.map((head, index) => {
                    return <TableCell className={classes.head} key={index} align="left">{head}</TableCell>
                  })
                }
              </TableRow>
            </TableHead>
            <TableBody>
              {
                props.data.map(item => {
                  return (
                    <TableRow key={item.id}>
                      <TableCell className={`${classes.row} table__title`}>{`${item.surname} ${item.name} ${item.patronymic}`}</TableCell>
                      <TableCell className={classes.row}>{item.phone}</TableCell>
                      <TableCell className={classes.row}>{item.email}</TableCell>
                      <TableCell>
                        <div className="table__cell">
                          <a className="table__cell-link" href={item.social_links.fb}><Fb /></a>
                          <a className="table__cell-link" href={item.social_links.instagram}><Inst /></a>
                        </div>
                      </TableCell>
                      <TableCell style={{ position: 'relative' }} className={classes.row}>
                        <TableSelect itemId={item.id} data={actionData} handleChange={handleChange} />
                      </TableCell>
                    </TableRow>
                  )
                })
              }
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    )
  } else {
    return (
      <div className={'table'}>
        <p>Дані відсутні...</p>
      </div>
    )
  }
}

DataTable.propTypes = {
  data: propTypes.arrayOf(propTypes.object).isRequired,
  orgId: propTypes.number.isRequired
}

export default connect()(DataTable)
