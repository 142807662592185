import React, { useState, useEffect, useRef } from "react";
import * as propTypes from "prop-types";
import { ReactComponent as Shevron } from "../../../assets/images/icons/shevron_bottom.svg";

function Select(props) {
  const [open, setOpen] = useState(false);

  const [selectedItem, setSelectedItem] = useState({
    name: "",
    id: "",
  });

  const {
    placeholder,
    name,
    register,
    handleChange,
    errors,
    isSubmitted,
    data,
    type,
    field,
    disabled,
  } = props;

  const getSelectItem = () => {
    let tempArr = data.filter((item) => {
      if (typeof field === "string") {
        return (item.name || item.title) === field;
      } else {
        return (item.name || item.title) === field.join(",");
      }
    });
    if (tempArr[0]) {
      selectedItem.name = tempArr[0].name || tempArr[0].title;
      selectedItem.id = tempArr[0].id;
      setSelectedItem({ ...selectedItem });
    }
  };

  useEffect(() => {
    if (type && data && field) {
      getSelectItem();
    }
  }, [data]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const selectItem = (item) => {
    selectedItem.name = item.name || item.title;
    selectedItem.id = item.id;
    setSelectedItem({ ...selectedItem });
  };

  return (
    <div className="custom-select">
      <div
        onMouseEnter={handleOpen}
        onMouseLeave={handleClose}
        onClick={handleOpen}
        className={`custom-select__field ${errors[name] ? "input-error" : ""} ${
          isSubmitted && !errors[name] ? "input-valid" : ""
        } ${disabled && "input-disabled"}`}
      >
        <input
          type="text"
          placeholder={placeholder}
          ref={register}
          name={name}
          value={selectedItem.id}
          disabled
          onChange={handleChange}
        />
        {!selectedItem.name.length ? (
          <p>{placeholder}</p>
        ) : (
          <p>
            {selectedItem.name.length > 30
              ? `${selectedItem.name.slice(0, 30)}...`
              : selectedItem.name}
          </p>
        )}
        <Shevron />
        {open && !disabled && (
          <div className="custom-select__inner" onMouseLeave={handleClose}>
            {data && data.length ? (
              data.map((item) => {
                return (
                  <div
                    key={item.id}
                    className="custom-select__item"
                    onClick={() => {
                      handleChange(item);
                      selectItem(item);
                    }}
                  >
                    {item.name || item.title}
                  </div>
                );
              })
            ) : (
              <div className="custom-select__item">No-data</div>
            )}
          </div>
        )}
      </div>
      {errors[name] && errors[name].type === "required" && (
        <span className="input-error-text">Будь ласка, заповніть поле.</span>
      )}
    </div>
  );
}

Select.propTypes = {
  placeholder: propTypes.string,
  name: propTypes.string.isRequired,
  register: propTypes.func.isRequired,
  handleChange: propTypes.func.isRequired,
  errors: propTypes.object.isRequired,
  isSubmitted: propTypes.bool.isRequired,
  data: propTypes.arrayOf(propTypes.object),
  type: propTypes.string,
  field: propTypes.oneOfType([propTypes.string, propTypes.array]),
  disabled: propTypes.bool,
};

export default Select;
