// all organisations

export const GET_ORG = 'GET_ORG'
export const REFETCH_ORG = 'REFETCH_ORG'
export const ERROR_ORG = 'ERROR_ORG'

// organisation details

export const GET_ORG_DETAILS = 'GET_ORG_DETAILS'
export const REFETCH_ORG_DETAILS = 'REFETCH_ORG_DETAILS'

// user owner organisations

export const GET_USER_ORG = 'GET_USER_ORG'
export const REFETCH_USER_ORG = 'REFETCH_USER_ORG'
export const ERROR_USER_ORG = 'ERROR_USER_ORG'


// user organisations requests

export const GET_USER_ORG_REQ = 'GET_USER_ORG_REQ'
export const REFETCH_USER_ORG_REQ = 'REFETCH_USER_ORG_REQ'
export const ERROR_USER_ORG_REQ = 'ERROR_USER_ORG_REQ'

// user organisations member

export const GET_USER_ORG_MEMBER = 'GET_USER_ORG_MEMBER'

// types organisations

export const GET_ORG_GO = 'GET_ORG_GO'
export const GET_ORG_GOV = 'GET_ORG_GOV'