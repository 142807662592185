import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import CountUp from "react-countup";
import Fab from "@material-ui/core/Fab";
import { Grid } from "@material-ui/core";
import { Fade } from "react-slideshow-image";
import pic from "../../assets/images/pages/main-img.png";
import user_add from "../../assets/images/icons/user_add.png";
import statistic from "../../assets/images/icons/statistic.png";
import { ReactComponent as SwipeDown } from "../../assets/images/icons/swipedown.svg";
import css from "./MainHeader.module.css";

const properties = {
  duration: 10000,
  transitionDuration: 500,
  infinite: true,
  arrows: false,
  indicators: true,
  autoplay: true,
};

function MainHeader(props) {
  const [isShow, setIsShow] = useState(true);

  const ref = React.useRef();
  useEffect(() => {
    ref.current.render();
    ref.current.setWidth();
  }, [ref, props.isLoading]);

  return (
    <div className={isShow ? "header" : "header header-hidden"}>
      <Grid container spacing={3} className={css.gridContainer}>
        <Grid item xs={12} sm={12} md={7} className="header__left">
          <Fade {...properties} className={css.fade}>
            <Grid container spacing={3} className={css.gridContainerLeft}>
              <Grid item xs={7} sm={7} md={6}>
                <h1 className="header__title">
                  Вітаємо
                  <br />
                  на платформі
                  <br />
                  <b>ОППК</b>
                </h1>
                <hr className="header__line" />
                <p className="header__dscr">
                  Щоб мати можливість прийняти участь в консультаціях, створити
                  свою чи приєднатись до існуючої Громадської Організації, будь
                  ласка, увійдіть або зареєструйтесь.
                </p>
                {!props.isAuthorized ? (
                  <Fab
                    variant="extended"
                    className={"button button-blue header__btn--blue"}
                    onClick={() => document.getElementById("register").click()}
                  >
                    <span>ЗАРЕЄСТРУВАТИСЬ</span>
                    <img src={user_add} alt="" />
                  </Fab>
                ) : (
                  <Link to="/profile" style={{ textDecoration: "none" }}>
                    <Fab
                      variant="extended"
                      className={"button button-blue header__btn--blue"}
                    >
                      <span>Мій профіль</span>
                    </Fab>
                  </Link>
                )}
              </Grid>
              <Grid item xs={5} sm={4} md={6}>
                <img className="header__img" src={pic} alt="" />
              </Grid>
            </Grid>

            <Grid container spacing={3} className={css.gridContainer}>
              <Grid item xs={7} sm={8} md={6}>
                <h1 className="header__title">
                  Є ідеї як
                  <br />
                  <b>ПОКРАЩИТИ</b>
                  <br />
                  НАШ СЕРВІС
                </h1>
                <hr className="header__line" />
                <p className="header__dscr">
                  Щоб мати можливість прийняти участь в консультаціях, створити
                  свою чи приєднатись до існуючої Громадської Організації, будь
                  ласка, увійдіть або зареєструйтесь.
                </p>
                {!props.isAuthorized ? (
                  <Fab
                    variant="extended"
                    className={"button button-blue header__btn--blue"}
                    onClick={() => document.getElementById("register").click()}
                  >
                    <span>ЗАРЕЄСТРУВАТИСЬ</span>
                    <img src={user_add} alt="" />
                  </Fab>
                ) : (
                  <Fab
                    variant="extended"
                    className={"button button-blue header__btn--blue"}
                  >
                    <span>Мій профіль</span>
                  </Fab>
                )}
              </Grid>
              <Grid item xs={5} sm={4} md={6}>
                <img className="header__img" src={pic} alt="" />
              </Grid>
            </Grid>
          </Fade>
        </Grid>

        <Grid item xs={12} md={5} className="header__right">
          <Fade {...properties} ref={ref}>
            <div className="header__right-wrap">
              <div className="header__right-row">
                <Grid item xs={6} md={6}>
                  <Link
                    to="/statistics"
                    style={{ textDecoration: "none", color: "initial" }}
                  >
                    <div className="header__right-inner">
                      <h2 className="header__counter">
                        +
                        <CountUp
                          start={0}
                          end={190}
                          delay={0.2}
                          duration={1.8}
                        />
                      </h2>
                      <p
                        style={{ textTransform: "uppercase" }}
                        className="header__counter-dscr"
                      >
                        консультацій запропоновано загалом
                      </p>
                    </div>
                  </Link>
                </Grid>
                <Grid item xs={6} md={6}>
                  <Link
                    to="/statistics"
                    style={{ textDecoration: "none", color: "initial" }}
                  >
                    <div className="header__right-inner">
                      <h2 className="header__counter">
                        +
                        <CountUp
                          start={0}
                          end={48}
                          delay={0.2}
                          duration={1.8}
                        />
                      </h2>
                      <p
                        style={{ textTransform: "uppercase" }}
                        className="header__counter-dscr"
                      >
                        ІГС зареєстровано на сайті
                      </p>
                    </div>
                  </Link>
                </Grid>
              </div>
              <hr className="header__line" />
              <div className="header__right-row">
                <Grid item xs={6} md={6}>
                  <Link
                    to="/statistics"
                    style={{ textDecoration: "none", color: "initial" }}
                  >
                    <div className="header__right-inner">
                      <h2 className="header__counter">
                        +
                        <CountUp
                          start={0}
                          end={18}
                          delay={0.2}
                          duration={1.8}
                        />
                      </h2>
                      <p
                        style={{ textTransform: "uppercase" }}
                        className="header__counter-dscr"
                      >
                        структурних підрозділів зареєстровано на сайті
                      </p>
                    </div>
                  </Link>
                </Grid>
                <Grid item xs={6} md={6}>
                  <Link
                    to="/statistics"
                    style={{ textDecoration: "none", color: "initial" }}
                  >
                    <div className="header__right-inner">
                      <h2 className="header__counter">
                        +
                        <CountUp
                          start={0}
                          end={29}
                          delay={0.2}
                          duration={1.8}
                        />
                      </h2>
                      <p
                        style={{ textTransform: "uppercase" }}
                        className="header__counter-dscr"
                      >
                        активних консультацій проводиться наразі
                      </p>
                    </div>
                  </Link>
                </Grid>
              </div>
              <Grid item xs={12}>
                <div className="header__btn-inner">
                  <Link
                    to="/statistics"
                    className={"header__btn--link"}
                    style={{ textDecoration: "none" }}
                  >
                    <Fab
                      variant="extended"
                      className={"button button-violet header__btn--violet"}
                    >
                      <span>БІЛЬШЕ СТАТИСТИКИ</span>
                      <img src={statistic} alt="" />
                    </Fab>
                  </Link>
                </div>
              </Grid>
            </div>

            <div className="header__right-wrap">
              <div className="header__right-row">
                <Grid item xs={6}>
                  <Link
                    to="/statistics"
                    style={{ textDecoration: "none", color: "initial" }}
                  >
                    <div className="header__right-inner">
                      <h2 className="header__counter">
                        +
                        <CountUp
                          start={0}
                          end={190}
                          delay={0.2}
                          duration={1.8}
                        />
                      </h2>
                      <p className="header__counter-dscr">ПИТАНЬ ВІДКРИТО</p>
                    </div>
                  </Link>
                </Grid>
                <Grid item xs={6}>
                  <Link
                    to="/statistics"
                    style={{ textDecoration: "none", color: "initial" }}
                  >
                    <div className="header__right-inner">
                      <h2 className="header__counter">
                        +
                        <CountUp
                          start={0}
                          end={48}
                          delay={0.2}
                          duration={1.8}
                        />
                      </h2>
                      <p className="header__counter-dscr">ГО СТВОРЕНО</p>
                    </div>
                  </Link>
                </Grid>
              </div>
              <hr className="header__line" />
              <div className="header__right-row">
                <Grid item xs={6}>
                  <Link
                    to="/statistics"
                    style={{ textDecoration: "none", color: "initial" }}
                  >
                    <div className="header__right-inner">
                      <h2 className="header__counter">
                        +
                        <CountUp
                          start={0}
                          end={18}
                          delay={0.2}
                          duration={1.8}
                        />
                      </h2>
                      <p className="header__counter-dscr">
                        ПРЕДСТАВНИКІВ БІЗНЕСУ ДОЛУЧИЛОСЯ
                      </p>
                    </div>
                  </Link>
                </Grid>
                <Grid item xs={6}>
                  <Link
                    to="/statistics"
                    style={{ textDecoration: "none", color: "initial" }}
                  >
                    <div className="header__right-inner">
                      <h2 className="header__counter">
                        +
                        <CountUp
                          start={0}
                          end={29}
                          delay={0.2}
                          duration={1.8}
                        />
                      </h2>
                      <p className="header__counter-dscr">
                        ДЕРЖ. ПРЕДСТАВНИКІВ ДОЛУЧИЛОСЯ
                      </p>
                    </div>
                  </Link>
                </Grid>
              </div>
              <Grid item xs={12}>
                <div className="header__btn-inner">
                  <Link
                    to="/statistics"
                    className={"header__btn--link"}
                    style={{ textDecoration: "none" }}
                  >
                    <Fab
                      variant="extended"
                      className={"button button-violet header__btn--violet"}
                    >
                      <span>БІЛЬШЕ СТАТИСТИКИ</span>
                      <img src={statistic} alt="" />
                    </Fab>
                  </Link>
                </div>
              </Grid>
            </div>
          </Fade>
        </Grid>
      </Grid>
      <div className={"toggle"}>
        <div className={"toggle__button"} onClick={() => setIsShow(!isShow)}>
          <div
            className={
              isShow ? "toggle__arrow" : "toggle__arrow toggle__arrow--reverse"
            }
          >
            <span></span>
            <span></span>
          </div>
        </div>
        <SwipeDown />
      </div>
    </div>
  );
}

function mapStateToProps({ auth, consulations }) {
  return {
    isAuthorized: auth.isAuthorized,
    isLoading: consulations.loading,
  };
}

export default connect(mapStateToProps)(MainHeader);
