import { GET_ORG_DETAILS, REFETCH_ORG_DETAILS } from '../actions/types/organisations'

const initialState = {
  organisation: null,
  questions: null
}

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_ORG_DETAILS:
      return {
        ...state,
        organisation: action.payload.organisation,
        questions: action.payload.questions
      }
    case REFETCH_ORG_DETAILS:
      return {
        ...state,
        organisation: null,
        questions: null
      }
    default:
      return state
  }
}
