import React from 'react'
import { Field, reduxForm } from 'redux-form'
import Input from "./fields/Input";
import CircularProgress from "@material-ui/core/CircularProgress";
import {ReactComponent as NextWhite} from '../../../assets/images/icons/btn_next_white.svg';
import Grid from "@material-ui/core/Grid";
import * as propTypes from "prop-types";
import {connect} from "react-redux";
import css from './RegisterForm.module.css'

const validate = values => {
	const errors = {};
	const requiredFields = [
		'surname',
		'name',
		'patronymic',
		'phone',
		'email',
		'password'
	];
	requiredFields.forEach(field => {
		if (!values[field]) {
			errors[field] = 'Будь ласка, заповніть поле.'
		}
	});
	if (
		values.email &&
		!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
	) {
		errors.email = 'Невірно вказаний email'
	}
	if (
		values.phone &&
		!/^\+?[0-9]{3}-?[0-9]{7,9}$/g.test(values.phone)
	) {
		errors.phone = 'Невірно вказаний телефон'
	}
	if (values.password && ([...values.password].length < 6 || [...values.password].length > 20)) {
		errors.password = 'Пароль повинен містити від 6 до 20 символів'
	}
	return errors;
};

let RegisterForm = props => {
	const { handleSubmit, invalid, loading } = props;
	return <form onSubmit={handleSubmit}>
		<Grid container spacing={0} className={css.gridContainer}>
			<Grid item sm={6} className={css.gridItem}>
				<Field name="surname" component={Input} placeholder="Ваше прізвище*" type="text"/>
			</Grid>
			<Grid item sm={6} className={css.gridItem}>
				<Field name="name" component={Input} placeholder="Ім’я*" type="text"/>
			</Grid>
			<Grid item sm={6} className={css.gridItem}>
				<Field name="patronymic" component={Input} placeholder="По-батькові*" type="text"/>
			</Grid>
			<Grid item sm={6} className={css.gridItem}>
				<Field name="phone" component={Input} placeholder="Номер телефону*" type="text"/>
			</Grid>
			<Grid item sm={6} className={css.gridItem}>
				<Field name="email" component={Input} placeholder="Ваш email*" type="text" variant={1}/>
			</Grid>
			<Grid item sm={6} className={css.gridItem}>
				<Field name="password" component={Input} placeholder="Пароль*" type="password" variant={1}/>
			</Grid>
		</Grid>
		{
			loading ? <CircularProgress style={{color: '#FEDD00', height: '41px', width: '41px'}}/>
				:	<button disabled={invalid} type="submit"><NextWhite/></button>
		}
	</form>
};

RegisterForm = reduxForm({
	form: 'register', validate
})(RegisterForm);

RegisterForm.propTypes = {
	dispatch: propTypes.func.isRequired,
	loading: propTypes.bool
};

function mapStateToProps({auth}) {
	return {
		loading: auth.loading
	}
}

export default connect(mapStateToProps)(RegisterForm);
