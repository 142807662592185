import React, { Component, Fragment } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/styles";
import CountUp from "react-countup";
import TableFilters from "../../containers/TableFilters";
import Table from "../../containers/tables/TableOrgPublic";
import Pagination from "../../UI/Pagination";
import CircularProgress from "@material-ui/core/CircularProgress";
import { FETCH_ORG, JOIN_TO_ORG } from "../../../actions/organisationsActions";
import { ReactComponent as Users } from "../../../assets/images/icons/users_grey.svg";

const useStyles = () => ({
  root: {
    color: "#992F87",
  },
});

class Main extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filters: {},
      pagination: {},
    };
  }

  joinToOrg = (data) => {
    this.props.dispatch(JOIN_TO_ORG(data)).then(() => {
      this.props.dispatch(FETCH_ORG());
    });
  };

  fetchData = (data) => {
    this.setState({
      filters: {
        ...this.state.filters,
        ...data,
      },
    });
    this.props.dispatch(FETCH_ORG({ ...data, ...this.state.pagination }));
  };

  fetchDataWithPagination = (data) => {
    this.setState({
      pagination: {
        ...this.state.pagination,
        ...data,
      },
    });
    this.props.dispatch(FETCH_ORG({ ...data, ...this.state.filters }));
  };

  render() {
    return (
      <div className="main">
        <div className="main__scrollable">
          <div className="main__count">
            <div>
              <h2 className="main__caption">Суб’єкти подання</h2>
            </div>
            <div className="main__counter">
              <CountUp start={0} end={12355} delay={0.1} duration={1.9} />
              <Users />
            </div>
          </div>
          <TableFilters fetchData={this.fetchData} />
          {!this.props.loading ? (
            <div className="table-wrapper">
              <CircularProgress
                className={this.props.classes.root}
              ></CircularProgress>
            </div>
          ) : (
            <Fragment>
              <Table
                data={this.props.organisations}
                joinToOrg={this.joinToOrg}
                user={this.props.user}
              />
              <Pagination
                variant="pagination"
                fetchData={this.fetchDataWithPagination}
                pagination={this.props.pagination}
              />
            </Fragment>
          )}
        </div>
      </div>
    );
  }
}

function mapStateToProps({ auth, organisations }) {
  return {
    user: auth.user,
    organisations: organisations.organisations.data,
    pagination: organisations.organisations.pagination,
    loading: organisations.loading,
  };
}

export default withRouter(
  connect(mapStateToProps)(withStyles(useStyles)(Main))
);
