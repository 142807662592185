import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as propTypes from "prop-types";
import { connect } from "react-redux";
import Input from "../../UI/inputs/CustomInput";
import Select from "../../UI/inputs/CustomSelect";
import Autocomplete from "../../UI/inputs/Autocomplete";
import Grid from "@material-ui/core/Grid";
import { FETCH_FILTERS } from "../../../actions/listsAction";
import { ReactComponent as Send } from "../../../assets/images/icons/icon_send-white.svg";

function CreateOrgForm(props) {
  const { orgTypes, citys, cityAreas, activity } = props.lists;

  let { type, organisation } = props;

  if (organisation && organisation.contactUser.id !== props.userId) {
    organisation = null;
  }

  const { register, handleSubmit, setValue, errors, formState } = useForm({
    defaultValues: {
      title: organisation ? organisation.title : "",
      type_id: "",
      edrpou: organisation ? organisation.edrpou : "",
      phone: organisation ? organisation.phone : "",
      email: organisation ? organisation.email : "",
      web_site: organisation ? organisation.web_site : "",
      fb: organisation ? organisation.social_links.fb : "",
      instagram: organisation ? organisation.social_links.instagram : "",
      telegram: organisation ? organisation.social_links.telegram : "",
      area_id: "",
      activity_field_id: "",
      street: organisation ? organisation.address.street : "",
      building: organisation ? organisation.address.building : "",
      flat: organisation ? organisation.address.flat : "",
    },
  });

  const { isSubmitted } = formState;

  useEffect(() => {
    props.dispatch(FETCH_FILTERS());
    register({ name: "title" }, { required: true });
    register({ name: "type_id" }, { required: true });
    register(
      { name: "edrpou" },
      {
        pattern: {
          value: /^[0-9]{8}$/,
          message: "Невірно вказаний ЄДРПОУ (8 цифр)",
        },
      }
    );
    register(
      { name: "phone" },
      {
        pattern: {
          value: /^\+?3?8?(0\d{9})$/,
          message: "Невірно вказаний телефон",
        },
      }
    );
    register(
      { name: "email" },
      {
        required: true,
        pattern: {
          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
          message: "Невірно вказаний email",
        },
      }
    );
    register({ name: "web_site" });
    register({ name: "fb" }, {});
    register({ name: "instagram" }, {});
    register({ name: "telegram" }, {});
    register({ name: "area_id" }, { required: true });
    register({ name: "activities" }, { required: true });
    register({ name: "street" }, {});
    register({ name: "building" }, {});
    register({ name: "flat" }, {});
  }, []);

  const handleChange = (evt) => {
    setValue(evt.target.name, evt.target.value);
  };

  const handleAutocomplete = (items) => {
    let result = items.map((el) => el.id);
    setValue("activities", result);
  };

  const submitForm = (data) => {
    let newOrg = {
      info: {
        title: data.title,
        type_id: data.type_id,
        activities: data.activities,
        edrpou: data.edrpou,
      },
      contactInfo: {
        email: data.email,
        phone: data.phone,
        web_site: data.web_site,
        contact_user_id: props.userId,
      },
      address: {
        area_id: data.area_id,
        street: data.street,
        building: data.building,
        flat: data.flat,
      },
      socialLinks: {
        fb: data.fb,
        instagram: data.instagram,
        telegram: data.telegram,
      },
    };
    props.onSubmit(newOrg);
  };

  return (
    <form className="organisation__form" onSubmit={handleSubmit(submitForm)}>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Input
            placeholder="Назва Організації*"
            name="title"
            register={register}
            handleChange={handleChange}
            errors={errors}
            isSubmitted={isSubmitted}
          />
        </Grid>
        <Grid container spacing={0}>
          <Grid item xs={12} md={6}>
            <Select
              placeholder="Тип організації*"
              name="type_id"
              register={register}
              handleChange={(val) => setValue("type_id", val.id)}
              errors={errors}
              isSubmitted={isSubmitted}
              data={orgTypes}
              type={type && type}
              field={organisation && organisation.type}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Input
              placeholder="ЄДРПОУ"
              name="edrpou"
              register={register}
              handleChange={handleChange}
              errors={errors}
              isSubmitted={isSubmitted}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Input
              placeholder="Телефон"
              name="phone"
              register={register}
              handleChange={handleChange}
              errors={errors}
              isSubmitted={isSubmitted}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Input
              placeholder="E-mail*"
              name="email"
              register={register}
              handleChange={handleChange}
              errors={errors}
              isSubmitted={isSubmitted}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Input
              placeholder="Сайт"
              name="web_site"
              register={register}
              handleChange={handleChange}
              errors={errors}
              isSubmitted={isSubmitted}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Input
              placeholder="Facebook"
              name="fb"
              register={register}
              handleChange={handleChange}
              errors={errors}
              isSubmitted={isSubmitted}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Input
              placeholder="Instagram"
              name="instagram"
              register={register}
              handleChange={handleChange}
              errors={errors}
              isSubmitted={isSubmitted}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Input
              placeholder="Telegram"
              name="telegram"
              register={register}
              handleChange={handleChange}
              errors={errors}
              isSubmitted={isSubmitted}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <div className="autocomplete-org">
            <Autocomplete
              placeholder="Почніть вводити напрямок діяльності*"
              name="activities"
              data={activity}
              register={register}
              handleChange={handleAutocomplete}
              errors={errors}
              isSubmitted={isSubmitted}
              type={type && type}
              field={organisation && organisation.activity}
            />
          </div>
        </Grid>
        <Grid container spacing={0}>
          <Grid item xs={12} md={6}>
            <Select
              placeholder="Район*"
              name="area_id"
              register={register}
              handleChange={(val) => setValue("area_id", val.id)}
              errors={errors}
              isSubmitted={isSubmitted}
              data={cityAreas}
              type={type && type}
              field={organisation && organisation.area}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Input
              placeholder="Вулиця"
              name="street"
              register={register}
              handleChange={handleChange}
              errors={errors}
              isSubmitted={isSubmitted}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Input
              placeholder="Дім"
              name="building"
              register={register}
              handleChange={handleChange}
              errors={errors}
              isSubmitted={isSubmitted}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Input
              placeholder="Квартира"
              name="flat"
              register={register}
              handleChange={handleChange}
              errors={errors}
              isSubmitted={isSubmitted}
            />
          </Grid>
        </Grid>
      </Grid>
      <button className="button-send">
        <span>ВІДПРАВИТИ</span>
        <Send />
      </button>
    </form>
  );
}

CreateOrgForm.propTypes = {
  onSubmit: propTypes.func,
  type: propTypes.string,
};

function mapStateToProps({ auth, organisation, lists }) {
  return {
    userId: auth.user.id,
    lists: lists.filters,
    organisation: organisation.organisation,
  };
}

export default connect(mapStateToProps)(CreateOrgForm);
