import React, { useState, useEffect, Fragment } from 'react'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'
import Table from '../../containers/tables/TableOrgMembers'
import TableConsult from '../../containers/tables/TableConsults'
import { FETCH_ORG_DETAILS } from '../../../actions/organisationsActions'

const headers = ['ID', 'Назва', 'Захід', 'Структурний підрозділ', 'Тема', 'Строки проведення', 'Статус', '']

const useStyles = makeStyles({
  root: {
    color: '#992F87'
  }
})

function OrgDetails (props) {
  const classes = useStyles()

  const [ loading, setLoading ] = useState(false)

  useEffect(() => {
    props.dispatch(FETCH_ORG_DETAILS(props.match.params.id))
      .then(() => {
        setLoading(true)
      })
  }, [])

  return (
    <div className="org-details">
      {!loading ?
        <div className="table-wrapper">
          <CircularProgress className={classes.root}></CircularProgress>
        </div> :
        <Fragment>
          <h1 className="org-details__title">{props.organisation.title}</h1>
          <h2 className="org-details__subtitle">УЧАСНИКИ</h2>
          <Table data={props.organisation.members} />
          <h2 style={{'marginTop': '70px'}} className="org-details__subtitle">ПИТАННЯ ВІД ГО</h2>
          <TableConsult data={props.questions} headers={headers} />
        </Fragment>
      }
    </div>
  )
}

function mapStateToProps({ organisation }) {
  return {
    organisation: organisation.organisation,
    questions: organisation.questions
  }
}

export default connect(mapStateToProps)(OrgDetails)
