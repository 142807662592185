import React, { useState } from 'react'
import * as propTypes from 'prop-types'
import Calendar from 'react-calendar'
import { ReactComponent as Shevron } from '../../../assets/images/icons/shevron_bottom.svg'
import 'react-calendar/dist/Calendar.css'

function CalendarInput(props) {
  const [ date, setDate ] = useState(new Date())

  const [ open, setOpen ] = useState(false)

  const {
    placeholder,
    name,
    register,
    handleChange,
    errors,
    isSubmitted
  } = props

  const onChange = (date) => {
    setDate(date)
    setOpen(!open)
  }

  const handleClick = () => {
    setOpen(!open)
  }

  return (
    <div style={{'position': 'relative'}} className="custom-select">
      <div
        onClick={handleClick}
        className={`custom-select__field ${errors[name] ? 'input-error' : ''} ${
          isSubmitted && !errors[name] ? 'input-valid' : ''
        }`}
      >
        <input
          type="text"
          placeholder={placeholder}
          ref={register}
          name={name}
          value={`${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`}
          disabled
          onChange={handleChange}
        />
        <p>{`${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`}</p>
        <Shevron />
      </div>
      {open &&
        <Calendar
          className="calendar-input"
          onChange={onChange}
          value={date}
        />
      }
      {errors[name] && errors[name].type === 'required' && (
        <span className="input-error-text">Будь ласка, заповніть поле.</span>
      )}
    </div>
  )
}

CalendarInput.propTypes = {
  placeholder: propTypes.string,
  name: propTypes.string.isRequired,
  register: propTypes.func.isRequired,
  handleChange: propTypes.func.isRequired,
  errors: propTypes.object.isRequired,
  isSubmitted: propTypes.bool.isRequired
}

export default CalendarInput
